import React, { useState, useEffect, useContext, memo } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useApiRequest } from "../../../core/api/useApiRequest";
import AppContext from "../../../core/context/AppContext";
import FolderIcon from "@mui/icons-material/Folder";
import s from "./NavFolderTreeStructure.module.scss";
import { useLocation } from "react-router-dom";

// IMPORTANT: import your new types
import { CustomTreeItem, IFolder } from "../../../core/types/types";

interface INavFolderTreeStructure {
  requestPath: string;
  setSelectedFolder?: any;
  selectedFolder?: any;
  header: string;
  dndType: string;
}

const NavFolderTreeStructure = ({
  requestPath,
  setSelectedFolder,
  selectedFolder,
  header,
  dndType,
}: INavFolderTreeStructure) => {
  const { sendRequest } = useApiRequest();
  const { forceFetchFolder } = useContext(AppContext);

  // Instead of TreeViewBaseItem[], use your custom item type
  const [treeData, setTreeData] = useState<CustomTreeItem[]>([]);

  const fetchFolders = async () => {
    try {
      const response: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const data: IFolder[] = response.data;

      const treeItems = buildTreeData(data);
      setTreeData(treeItems);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, [forceFetchFolder]);

  // Build the data in a shape that includes both 'label' and 'name'
  const buildTreeData = (items: IFolder[]): CustomTreeItem[] => {
    const idToNodeMap: { [key: number]: CustomTreeItem } = {};

    items.forEach((item) => {
      idToNodeMap[item.id] = {
        id: item.id.toString(),
        label: item.name, // MUI expects 'label'
        name: item.name, // your custom property
        children: [],
      };
    });

    const roots: CustomTreeItem[] = [];

    items.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parent_id === 0) {
        roots.push(node);
      } else {
        const parent = idToNodeMap[item.parent_id];
        if (parent) {
          parent.children!.push(node);
        }
      }
    });

    // Optionally assign levels
    assignLevels(roots, 0);
    return roots;
  };

  const assignLevels = (nodes: CustomTreeItem[], level: number) => {
    for (const node of nodes) {
      node.level = level;
      if (node.children && node.children.length > 0) {
        assignLevels(node.children as CustomTreeItem[], level + 1);
      }
    }
  };

  return (
    <div className={s.outer_container}>
      {treeData.map((node) => (
        <FolderNode key={node.id} node={node} />
      ))}
    </div>
  );
};

// We also need to type the node here:
const FolderNode = memo(({ node }: { node: CustomTreeItem }) => {
  const location = useLocation();
  const splitLocation = location.pathname.split("/")[1];

  return (
    <Droppable
      droppableId={`folder-${node.id}`}
      type={splitLocation}
      isDropDisabled={false}
      isCombineEnabled={false}
    >
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            data-level={node.level || 0}
            data-node-type="folder"
            className={`${s.each_node} ${snapshot.isDraggingOver ? s.active_drop_zone : ""}`}
          >
            <div className={s.placeholder}>{provided.placeholder}</div>
            <div className={s.test}>
              <FolderIcon />
              {/* Because we defined 'name' on our custom item data, 
                  we can safely do `node.name` or use node.label */}
              <span>{node.name}</span>
            </div>
          </div>

          {/* Render children */}
          {node.children &&
            node.children.map((child) => (
              <FolderNode key={child.id} node={child as CustomTreeItem} />
            ))}
        </>
      )}
    </Droppable>
  );
});

export default NavFolderTreeStructure;
