//This component is responsible for selecting caritain view from available,
//It is found inside FabricJS component (top right of cavas)

import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./SingleComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SelectOption from "../SelectOption/SelectOption";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../core/api/useApiRequest";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";
import ProductContext from "../../core/context/ProductContext";
import { filterViewToShow } from "../ProductComponent/filterViewToShow";
import { useNavigate } from "react-router-dom";
import ProjectContext from "../../core/context/ProjectContext";
import { uniqueID, useNotification } from "../../core/context/notifications/NotificationProvider";
import handleLoadCanvasProject from "../../features/Fabric-JS/fabric-functions/LoadProject";

interface ISingleComponent {
  data: any;
  variant: string;
  handleClick: (variant: any | null) => void;
  isPreview?: boolean;
}
const selectStyle: React.CSSProperties = {
  position: "absolute",
};

const SingleComponent = ({ data, handleClick, variant, isPreview }: ISingleComponent) => {
  const { sendRequest } = useApiRequest();
  const [openMore, setOpenMore] = useState<boolean>(false);
  const { t } = useTranslation();
  const { activeGlobalState, setActiveGlobalState, canvasContext } = useContext(FabricJSContextNew);
  const { setProducts } = useContext(ProductContext);
  const { setProjects } = useContext(ProjectContext);
  const selectOptionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const notification = useNotification();

  const deleteViewHandler = async () => {
    const urlType =
      activeGlobalState.canvasType === "projects"
        ? "projects"
        : activeGlobalState.canvasType === "templates"
        ? "templates"
        : null;

    const actionType =
      activeGlobalState.canvasType === "projects"
        ? "version_delete"
        : activeGlobalState.canvasType === "templates"
        ? "view_delete"
        : null;

    setOpenMore(false);

    const response = await sendRequest(
      "post",
      `/creator/2d/${urlType}/${activeGlobalState.fullJSON.id}/batch`,
      [
        {
          event: actionType,
          payload: {
            uuid: data.uuid,
          },
        },
      ]
    );

    if (response && response.data) {
      const updatedData = response.data as any;
      if (urlType === "templates") {
        notification({
          id: uniqueID,
          type: "Success",
          message: "Project deleted",
        });
        const newActiveView = updatedData.views[updatedData.views.length - 1];

        // Update products state
        setProducts((prevItems: any) =>
          prevItems.map((item: any) =>
            item.id === updatedData.id ? { ...item, views: updatedData.views } : item
          )
        );

        // Update activeGlobalState
        setActiveGlobalState((prevData: any) => {
          const newState = {
            ...prevData,
            fullJSON: updatedData,
            selectedView: newActiveView,
          };

          if (!newActiveView) {
            navigate("/dashboard");
            return;
          }
          const viewToShow = filterViewToShow(
            newActiveView.uuid,
            newState.fullJSON,
            activeGlobalState.canvasType
          );
          handleLoadCanvasProject(canvasContext, viewToShow.canvasJSON);

          return newState;
        });
      } else if (urlType === "projects") {
        notification({
          id: uniqueID,
          type: "Success",
          message: "Product deleted",
        });
        const newActiveVersion = updatedData.versions[updatedData.versions.length - 1];
        // Update products state

        setProjects((prevItems: any) =>
          prevItems.map((item: any) =>
            item.id === updatedData.id ? { ...item, versions: updatedData.versions } : item
          )
        );
        // Update activeGlobalState
        setActiveGlobalState((prevData: any) => {
          const newState = {
            ...prevData,
            fullJSON: updatedData,
            selectedView: newActiveVersion,
          };

          if (!newActiveVersion) {
            navigate("/dashboard");
            return;
          }
          const viewToShow = filterViewToShow(
            newActiveVersion?.view_uuid,
            newState.fullJSON,
            activeGlobalState.canvasType
          );
          handleLoadCanvasProject(canvasContext, viewToShow.canvasJSON);

          return newState;
        });
      }
    }
  };

  const options = [
    {
      function: deleteViewHandler,
      name: t("Delete"),
    },
  ];

  const handleClickOutside = (event: MouseEvent) => {
    if (selectOptionRef.current && !selectOptionRef.current.contains(event.target as Node)) {
      setOpenMore(false);
    }
  };

  const openMoreHandler = (e: any) => {
    e.stopPropagation();
    setOpenMore((prevState) => !prevState);
  };

  useEffect(() => {
    if (openMore) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMore]);

  return (
    <div className={s.container} onClick={() => handleClick(data)}>
      <ComponentBackground id={data.id}>
        <div
          className={`${s.img_container} ${
            variant === "small" ? s.small : variant === "medium" ? s.medium : s.large
          }`}
        >
          {data?.thumbnail ? (
            <img src={data.thumbnail || activeGlobalState.generateThumbnail} alt={data.uuid} />
          ) : null}
        </div>
        {!isPreview ? (
          <div className={s.openMore} ref={selectOptionRef}>
            <MoreHorizIcon fontSize="large" onClick={(e) => openMoreHandler(e)} />
            {openMore ? <SelectOption options={options} style={selectStyle} /> : null}
          </div>
        ) : null}
      </ComponentBackground>
    </div>
  );
};

export default SingleComponent;
