import React, { useContext, useEffect, useState } from "react";
import s from "./CreateProject.module.scss";
import RenderActionComponent from "../../../RenderActionComponent/RenderActionComponent";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../../../Button/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import infoImg from "./ImportFile/assets/info.svg";
import Products from "./Products/Products";
import Papers from "./Papers/Papers";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import ProjectContext from "../../../../core/context/ProjectContext";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import FolderTreeStructure from "../../../FolderComponents/FolderTreeStructure/FolderTreeStructure";
import CreateModal from "../CreateModal";

const initialValues = {
  projectName: "",
};
interface TCreateProject {
  setCloseModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateProject = ({ setCloseModal }: TCreateProject) => {
  const { t } = useTranslation();
  const nav = [
    // { link: "search", name: "Search" },
    // { link: "last_used", name: "LastUsed" },
    { link: "search", name: "Search" },
    { link: "favourite", name: "Favourite" },
    { link: "last_used", name: "LastUsed" },
    { link: "products", name: "Products" },
    //{ link: "import_file", name: "ImportFile" },
    //{ link: "paper", name: "Paper" },
    // { link: "other_size", name: "OtherSizes" },
    // { link: "saved", name: "Saved" },
  ];
  const navigate = useNavigate();
  const notification = useNotification();
  const [choosenFolder, setChoosenFolder] = useState<number>(0);
  const { sendRequest, isLoading } = useApiRequest();

  const { setProjects } = useContext(ProjectContext);

  const {
    canvasContext,
    setActiveGlobalState,
    setTempActiveGlobalState,
    tempActiveGlobalState,
    setCreatingNewWork,
  } = useContext(FabricJSContextNew);

  const [actionState, setActionState] = useState<string>("products");
  const [inputs, setInputs] = useState<any>(initialValues);
  const [fileToSend, setFileToSend] = useState<any>(null); // Store file to be uploaded TODO: update functionality

  useEffect(() => {
    setInputs((prevInputs: any) => ({
      ...prevInputs,
      name: tempActiveGlobalState.name || "",
    }));
  }, [tempActiveGlobalState]);

  const inputHandler = (e: any) => {
    const { name, value } = e.target;
    setInputs((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    setTempActiveGlobalState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const actions: Record<string, JSX.Element> = {
    search: <div>{t("Search")}</div>,
    favourite: <div>{t("Favourite")}</div>,
    last_used: <div>{t("LastUsed")}</div>,
    // last_used: <div>{t("LastUsed")}</div>,
    products: (
      <Products
        setTempActiveGlobalState={setTempActiveGlobalState}
        handleDoubleClick={() => handleCreateNewProject()}
      />
    ),
    paper: <Papers />,
    // other_size: <div>{t("OtherSizes")}</div>,
    // saved: <div>{t("Saved")}</div>,
  };

  //POST CREATE PROJECT
  const handleCreateNewProject = async () => {
    try {
      const response = await sendRequest("post", `creator/2d/projects`, {
        template_id: tempActiveGlobalState?.fullJSON?.id,
        name: inputs.projectName,
        category_id: choosenFolder,
      });

      if (response && response.data) {
        notification({
          id: uniqueID,
          type: "SUCCESS",
          message: t("Create.ProjectDrafCreated"),
        });
        const responseData = response.data as any;

        setActiveGlobalState({
          fullJSON: responseData,
          canvasType: "projects",
          selectedView: {
            ...responseData.versions[0],

            view_uuid: tempActiveGlobalState.selectedView.uuid,
          },
          generateThumbnail: null,
        });

        setProjects((prevProjects: any) => [...prevProjects, responseData]);
        setCreatingNewWork((prevState: any) => ({
          ...prevState,
          workType: "project",
          new: true,
        }));

        navigate(`/design-studio/project/${responseData?.token}&is_draft=true`);
        if (setCloseModal) {
          setCloseModal((prevState: boolean) => !prevState);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  // const [width, setWidth] = useState("");
  // const [height, setHeight] = useState("");
  // const [unit, setUnit] = useState("MM");
  // const [dpi, setDpi] = useState("");

  // const handleDimensionChange = (e: any) => {
  //   const { name, value } = e.target;
  //   if (name === "width") {
  //     setWidth(value);
  //   } else if (name === "height") {
  //     setHeight(value);
  //   }
  // };

  // const handleUnitChange = (event: SelectChangeEvent) => {
  //   setUnit(event.target.value as string);
  // };
  // const calculateDpi = () => {
  //   const widthNumber = parseFloat(width);
  //   const heightNumber = parseFloat(height);

  //   if (!isNaN(widthNumber) && !isNaN(heightNumber) && canvasContext) {
  //     const widthInInches = unit === "MM" ? widthNumber / 25.4 : widthNumber;
  //     const heightInInches = unit === "MM" ? heightNumber / 25.4 : heightNumber;

  //     const canvasWidthInPixels = canvasContext.getWidth();
  //     const canvasHeightInPixels = canvasContext.getHeight();

  //     const dpiWidth = canvasWidthInPixels / widthInInches;
  //     const dpiHeight = canvasHeightInPixels / heightInInches;

  //     const calculatedDpi = Math.min(dpiWidth, dpiHeight);
  //     setDpi(calculatedDpi.toFixed(2));
  //   }
  // };
  // useEffect(() => {
  //   calculateDpi();
  // }, [width, height, unit, canvasContext]);

  return (
    <div className={s.container}>
      {/* TODO: handle adding  */}
      <CreateModal
        type="project"
        setFileToSend={setFileToSend}
        onFileSelectedAndInvoke={() => console.log("File selected")}
      >
        <div className={s.left}>
          <div className={s.nav}>
            {nav.map((el, i: number) => (
              <span
                className={actionState === el.link ? s.active : undefined}
                key={`Create-project-map-${i}`}
                onClick={() => setActionState(el.link)}
              >
                {el.name === "Search" && (
                  <SearchIcon className={s.search_icon} />
                )}
                {t(el.name)}
              </span>
            ))}
            <span></span>
          </div>
          <RenderActionComponent actions={actions} action={actionState} />
        </div>
      </CreateModal>
    </div>
    //     <div className={s.right}>
    //       <div className={s.header}>
    //         <h4>{t("Information")}</h4>
    //         <div className={s.img_container}>
    //           <img src={infoImg} alt={infoImg} />
    //         </div>
    //       </div>

    //       <TextField
    //         name="projectName"
    //         label={t("Name")}
    //         value={inputs.projectName}
    //         onChange={(e) => inputHandler(e)}
    //       />

    //       <FolderTreeStructure
    //         requestPath="projects"
    //         setSelectedFolder={setChoosenFolder}
    //       />

    //       <div className={s.header}>
    //         <h4>{t("Working area")}</h4>
    //         <div className={s.img_container}>
    //           <img src={infoImg} alt={infoImg} />
    //         </div>
    //       </div>

    //       <div className={s.working_space}>
    //         <TextField
    //           sx={{ width: "90px" }}
    //           label={t("Width")}
    //           name="width"
    //           onChange={handleDimensionChange}
    //         />
    //         x
    //         <TextField
    //           sx={{ width: "90px" }}
    //           label={t("Height")}
    //           name="height"
    //           onChange={handleDimensionChange}
    //         />
    //         <FormControl sx={{ width: "112px" }}>
    //           <InputLabel id="unit-select-label">{t("Unit")}</InputLabel>
    //           <Select
    //             labelId="unit-select-label"
    //             id="unit-select"
    //             value={unit}
    //             label="Unit"
    //             onChange={handleUnitChange}
    //           >
    //             <MenuItem value="MM">MM"</MenuItem>
    //             <MenuItem value="INCHES">{t("Inches")}</MenuItem>
    //           </Select>
    //         </FormControl>
    //         <TextField
    //           sx={{ width: "90px" }}
    //           label="DPI"
    //           value={dpi}
    //           InputProps={{ readOnly: true }}
    //         />
    //         <span>DIP (punkt ca cal)</span>
    //       </div>

    //       <div className={s.orientation}>
    //         <div className={s.header}>
    //           <h4>{t("Working area")}</h4>
    //           <div className={s.img_container}>
    //             <img src={infoImg} alt={infoImg} />
    //           </div>

    //           <div className={s.paper_orientation}>
    //             <div className={s.r}>
    //               <span>R</span>
    //             </div>
    //           </div>

    //           <div className={s.paper_orientation}>
    //             <div className={s.l}>
    //               <span>L</span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className={s.bottom}>
    //     <div>
    //       <ChevronLeftIcon />
    //       <a>Powrót</a>
    //     </div>

    //     <a href="">Zapisz szablon</a>

    //     <Button
    //       type="button"
    //       text={isLoading ? t("Loading") : t("Create.CreateProject")}
    //       handleClick={() => handleCreateNewProject()}
    //       isLoading={isLoading}
    //     />
    //   </div>
    // </div>
  );
};

export default CreateProject;
