import s from "./Chip.module.scss";

interface IChipComponent {
  label: string;
  isActive: boolean;
  handleClick: any;
}

const ChipComponent = ({ label, isActive, handleClick }: IChipComponent) => {
  return (
    <div className={`${s.chip} ${isActive ? s.active : ""}`} onClick={handleClick}>
      {label}
    </div>
  );
};

export default ChipComponent;
