import React from "react";
import addObject from "./AddObject";
import addImage from "./AddImage";
import addText from "./AddText";
import { fabric } from "fabric";

export interface IFileInformation {
  fileType: string;
  level: number | null;
  url: string;
  name: string;
}

export interface ITextInformation {
  text: string;
  fontSize: string | number;
  color: string;
  fileType: string;
}

type SetCanvasObjectsFunction = (objects: fabric.Object[]) => void;

type AddFunction = (
  canvas: any,
  fileInformation: IFileInformation | ITextInformation,
  setCanvasObjects: SetCanvasObjectsFunction
) => void;

const addStrategy: Record<string, AddFunction> = {
  svg: addObject,
  img: addImage,
  text: addText,
};

const addGateway = async (
  canvas: any,
  fileInformation: IFileInformation | ITextInformation,
  setCanvasObjects: SetCanvasObjectsFunction
): Promise<any> => {
  if (!fileInformation || !fileInformation.fileType) {
    console.error("File information is missing or does not have a fileType.");
    return null;
  }

  const handler = addStrategy[fileInformation.fileType];

  if (handler) {
    return await handler(canvas, fileInformation, setCanvasObjects);
  } else {
    console.error(`Unsupported file type: ${fileInformation.fileType}`);
    return null;
  }
};

export default addGateway;
