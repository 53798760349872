import React, { useContext, useEffect, useState } from "react";
import s from "./CreateProduct.module.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../../../Button/Button";
import { TextField } from "@mui/material";
import infoImg from "./ImportFile/assets/info.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import AuthContext from "../../../../core/context/AuthContext";

import FolderTreeStructure from "../../../FolderComponents/FolderTreeStructure/FolderTreeStructure";
import CreateModal from "../CreateModal";
import RenderActionComponent from "../../../RenderActionComponent/RenderActionComponent";
import SearchIcon from "@mui/icons-material/Search";
import Templates from "./Templates/Templates";

const initialValues = {
  productName: "",
  viewName: "",
};

const CreateProduct = ({ setCloseModal }: any) => {
  const { t } = useTranslation();
  const notification = useNotification();
  const { sendRequest } = useApiRequest();

  const {
    canvasContext,
    setCanvasObjectList,
    setActiveGlobalState,
    setCreatingNewWork,
  } = useContext(FabricJSContextNew);

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [input, setInput] = useState(initialValues);
  const [loadingState, setLoadingState] = useState({
    isCreatingProduct: false,
    isUploadingFile: false,
  });

  const [fileToSend, setFileToSend] = useState<any>(null); // Store file to be uploaded
  const [selectedFolder, setSelectedFolder] = useState<number>(0);
  const [actionState, setActionState] = useState<string>("templates");

  const nav = [
    { link: "search", name: "Search" },
    { link: "templates", name: "AllFiles" },
  ];

  const actions: Record<string, JSX.Element> = {
    search: <div>{t("Search")}</div>,
    templates: <Templates />,
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const createProductTemplate = async () => {
    setLoadingState((prev) => ({ ...prev, isCreatingProduct: true }));
    try {
      const response: any = await sendRequest("post", "/creator/2d/templates", {
        name: input.productName,
        category_id: selectedFolder,
      });
      if (response?.data) {
        setActiveGlobalState({
          fullJSON: response.data,
          canvasType: "templates",
          selectedView: response.data.views[0],
        });
        return response.data;
      }
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("Error creating product template"),
      });
    } catch (error) {
      console.error(error);
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("Error creating product template"),
      });
    } finally {
      setLoadingState((prev) => ({ ...prev, isCreatingProduct: false }));
    }
    return null;
  };

  const uploadFile = async (fileData: any) => {
    if (!fileData || !fileData.file) {
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("File data missing"),
      });
      return null;
    }

    setLoadingState((prev) => ({ ...prev, isUploadingFile: true }));
    const formData = new FormData();
    formData.append("attachment", fileData.file);
    formData.append("draft_id", fileData.draft_id);

    try {
      const response = await sendRequest(
        "post",
        "/upload-pending-attachment",
        formData
      );
      if (!response) {
        notification({
          id: uniqueID(),
          type: "ERROR",
          message: t("Error uploading file"),
        });
        return null;
      }
      return response;
    } catch (err) {
      console.error(err);
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("Error uploading file"),
      });
      return null;
    } finally {
      setLoadingState((prev) => ({ ...prev, isUploadingFile: false }));
    }
  };

  const handleLoggedInFlow = async () => {
    const productResponse: any = await createProductTemplate();
    if (!productResponse || !fileToSend) {
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("File or product data is missing"),
      });
      return;
    }

    const fileResponse = await uploadFile(fileToSend);
    if (fileResponse && fileResponse.url) {
      setCreatingNewWork((prevState: any) => ({
        ...prevState,
        newWorkImageUrl: fileResponse.url,
        workType: "product",
        new: true,
      }));

      notification({
        id: uniqueID(),
        type: "SUCCESS",
        message: t("Product draft created"),
      });
      if (typeof setCloseModal === "function") {
        setCloseModal(false);
      }
      navigate(`/design-studio/product/${productResponse.token}&is_draft=true`);
    }
  };

  const handleNotLoggedInFlow = async (fileData: any) => {
    const fileResponse = await uploadFile(fileData);
    if (fileResponse && fileResponse.url) {
      const fileInfoToAdd = {
        url: fileResponse.url,
        name: fileData.file.name,
        level: 1,
        fileType: "img",
      };

      notification({
        id: uniqueID(),
        type: "SUCCESS",
        message: t("File uploaded and added to canvas"),
      });
      if (typeof setCloseModal === "function") {
        setCloseModal(false);
      }
    }
  };
  console.log(typeof selectedFolder, "selected");

  const handleCreateNewProduct = () => {
    if (user) {
      handleLoggedInFlow(); // Trigger creation flow when button is clicked
    } else if (fileToSend && !user) {
      handleNotLoggedInFlow(fileToSend); // Auto-upload when not logged in
    } else {
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("Please select a file to proceed"),
      });
    }
  };
  useEffect(() => {
    if (fileToSend) {
      if (user) {
        handleLoggedInFlow();
      } else {
        handleNotLoggedInFlow(fileToSend);
      }
    }
  }, [fileToSend]);
  const isLoading =
    loadingState.isCreatingProduct || loadingState.isUploadingFile;
  return (
    <div className={s.container}>
      <CreateModal
        type={"product"}
        setFileToSend={setFileToSend}
        onFileSelectedAndInvoke={!user ? handleNotLoggedInFlow : undefined} // Only invoke automatically if not logged in
      >
        <div className={s.left}>
          <div className={s.nav}>
            {nav.map((el, i: number) => (
              <span
                className={actionState === el.link ? s.active : undefined}
                key={`Create-project-map-${i}`}
                onClick={() => setActionState(el.link)}
              >
                {el.name === "Search" && (
                  <SearchIcon className={s.search_icon} />
                )}
                {t(el.name)}
              </span>
            ))}
            <span></span>
          </div>
          <RenderActionComponent actions={actions} action={actionState} />
        </div>
      </CreateModal>
    </div>
  );
};

export default CreateProduct;
