import React from "react";
import s from "./Papers.module.scss";
import { dummyPapers } from "../../../../../dummy-data/papers";
import PaperComponent from "../../../../PaperComponent/PaperComponent";
const Papers = () => {
  return (
    <div className={s.container}>
      {dummyPapers.map((el, index) => (
        <PaperComponent
          key={`paper-map-${index}-create-project`}
          paper={el}
          handleClick={() => {}}
        />
      ))}
    </div>
  );
};

export default Papers;
