import { Toolbar } from "@mui/material";
import React from "react";

type SVGWrapperProps = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isActive?: boolean;
  isLoggedIn?: boolean;
};

const SVGWrapper: React.FC<SVGWrapperProps> = ({ Icon, isActive, isLoggedIn }) => {
  const color = !isLoggedIn
    ? "#808d98" // User not logged in
    : isActive
    ? "#00ff00" // Active and logged in
    : "#ffffff"; // Logged in but not active

  return <Icon style={{ fill: color }} />;
};

export default SVGWrapper;
