import React, { useCallback, useContext, useState } from "react";

import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import addWARect from "../../../../../fabric-functions/WorkingAreaLogic/RectWA";
import addWACircle from "../../../../../fabric-functions/WorkingAreaLogic/CircleWA";
import {
  exportCanvasToImage,
  exportWorkingAreaToImage,
} from "../../../../../fabric-functions/ExportCanvasToImage";
import { fabric } from "fabric";
const DevMode = () => {
  const { canvasContext, setCanvasObjectList, canvasSize } = useContext(FabricJSContextNew);
  const [jsonOutput, setJsonOutput] = useState("");
  //   const addMainObject = useCallback(() => {
  //     const addObject = {
  //       url: test,
  //       name: "tshirt",
  //       level: 1,
  //       fileType: "img",
  //     };
  //     addGateway(canvasContext, addObject, setCanvasObjectList);
  //   }, [canvasContext, setCanvasObjectList]);

  // const handleAddWARect = useCallback(() => {
  //   addWARect(canvasContext);
  // }, [canvasContext]);

  // const handleAddWACircle = useCallback(() => {
  //   addWACircle(canvasContext);
  // }, [canvasContext]);

  const handleSaveCanvas = useCallback(() => {
    const canvasJson = canvasContext.toJSON();
    setJsonOutput(JSON.stringify(canvasJson, null, 2));
  }, [canvasContext]);
  function createCartesianAxes(canvasSize: { width: number; height: number }, zoomLevel: number) {
    const objects: fabric.Object[] = [];

    // Set the origin to (0, 0)
    const centerX = 0;
    const centerY = 0;

    // Define tick spacings based on zoom level
    let majorTickSpacingPx: number;
    let minorTickSpacingPx: number;

    if (zoomLevel <= 1) {
      majorTickSpacingPx = 100; // 10 mm
      minorTickSpacingPx = 10; // 5 mm
    } else if (zoomLevel <= 2) {
      majorTickSpacingPx = 10; // 5 mm
      minorTickSpacingPx = 5; // 1 mm
    } else {
      majorTickSpacingPx = 5; // 1 mm
      minorTickSpacingPx = 1; // 0.5 mm
    }

    // Helper to convert pixels into mm
    const pxToMm = (px: number) => (px / 10).toFixed(1);

    // Draw the X-axis (horizontal line through the origin)
    const xAxisLine = new fabric.Line(
      [-canvasSize.width / 2, centerY, canvasSize.width / 2, centerY],
      {
        stroke: "black",
        strokeWidth: 1,
        selectable: false,
        evented: false,
      }
    );
    objects.push(xAxisLine);

    // Draw the Y-axis (vertical line through the origin)
    const yAxisLine = new fabric.Line(
      [centerX, -canvasSize.height / 2, centerX, canvasSize.height / 2],
      {
        stroke: "black",
        strokeWidth: 1,
        selectable: false,
        evented: false,
      }
    );
    objects.push(yAxisLine);

    // Function to create X-axis ticks
    function createXTicks() {
      // Add ticks in both positive and negative directions
      for (let x = -canvasSize.width / 2; x <= canvasSize.width / 2; x += majorTickSpacingPx) {
        if (x !== 0) {
          // Create major tick
          const tickLine = new fabric.Line([x, centerY - 10, x, centerY + 10], {
            stroke: "black",
            strokeWidth: 1.5,
            selectable: false,
            evented: false,
            opacity: 0.4,
          });
          objects.push(tickLine);

          // Add label
          const mmValue = pxToMm(Math.abs(x));
          const sign = x < 0 ? "-" : "+";
          const label = new fabric.Text(`${sign}${mmValue} mm`, {
            left: x + 2,
            top: centerY + 12,
            fontSize: 12,
            selectable: false,
            evented: false,
            opacity: 0.7,
          });
          objects.push(label);
        }
      }
    }

    // Function to create Y-axis ticks
    function createYTicks() {
      // Add ticks in both positive and negative directions
      for (let y = -canvasSize.height / 2; y <= canvasSize.height / 2; y += majorTickSpacingPx) {
        if (y !== 0) {
          // Create major tick
          const tickLine = new fabric.Line([centerX - 10, y, centerX + 10, y], {
            stroke: "black",
            strokeWidth: 1.5,
            selectable: false,
            evented: false,
            opacity: 0.4,
          });
          objects.push(tickLine);

          // Add label
          const mmValue = pxToMm(Math.abs(y));
          const sign = y < 0 ? "-" : "+";
          const label = new fabric.Text(`${sign}${mmValue} mm`, {
            left: centerX + 12,
            top: y - 6,
            fontSize: 12,
            selectable: false,
            evented: false,
            opacity: 0.7,
          });
          objects.push(label);
        }
      }
    }

    // Generate ticks for X and Y axes
    createXTicks();
    createYTicks();

    // Return the complete Cartesian coordinate system as a group
    return new fabric.Group(objects, {
      selectable: false,
      evented: false,
      level: 6,
    });
  }

  // Usage
  const zoomLevel = 0;

  function addCartesianAxes() {
    const axes = createCartesianAxes(canvasSize, zoomLevel);
    canvasContext.add(axes);
  }

  return (
    <div>
      {/* <button onClick={addMainObject}>add main object</button> */}
      {/* <button style={{ marginTop: "30px" }} onClick={handleAddWARect}>
        Create Workable Area
      </button>
      <button style={{ marginTop: "30px" }} onClick={handleAddWACircle}>
        Create Workable Area Circle
      </button> */}
      <button onClick={addCartesianAxes}> axis</button>
      <button onClick={() => exportCanvasToImage(canvasContext)}>Export canvas to image</button>
      <button onClick={() => exportWorkingAreaToImage(canvasContext)}>
        Export working area to image
      </button>
      <pre>{jsonOutput}</pre>
    </div>
  );
};

export default DevMode;
