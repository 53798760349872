import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import s from "./Text.module.scss";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TitleIcon from "@mui/icons-material/Title";
import { dummyText } from "../../../../../dummy-data/texts";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import addGateway from "../../../../../features/Fabric-JS/fabric-functions/AddToCanvas/AddGateway";
const Text = () => {
  const [search, setSearch] = useState<string>("");
  const { canvasContext, setCanvasObjectList } = useContext(FabricJSContextNew);

  const handleAddText = (text: string) => {
    const textData = {
      text,
      fontSize: 16,
      color: "#000000",
      fileType: "text",
      level: 4,
    };

    addGateway(canvasContext, textData, setCanvasObjectList);
  };
  return (
    <div className={s.container}>
      <TextField
        className={s.input}
        size="small"
        variant="outlined"
        label="Szukaj"
        sx={{
          ".MuiOutlinedInput-root": {
            borderRadius: "4px",
          },

          "& .css-yjsfm1": {
            fontFamily: "Akrobat",
            fontSize: "16px !important",
            fontWeight: 600,
            color: "#575757",
          },
        }}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className={s.half_container}>
        <div onClick={() => handleAddText("Nowy Title")}>
          <TitleIcon />
          <span>Dodaj tytuł</span>
        </div>
        <div onClick={() => handleAddText("Nowy Text")}>
          <TextIncreaseIcon />
          <span>Dodaj text</span>
        </div>
      </div>
    </div>
  );
};

export default Text;
