import React from "react";
import s from "./SelectOption.module.scss";
import { Link, useNavigate } from "react-router-dom";

interface Option {
  name: string;
  link?: string;
  function?: () => void;
}

interface ISelectOption {
  options: Option[];
  style?: React.CSSProperties;
}

const SelectOption: React.FC<ISelectOption> = ({ options, style }) => {
  const navigate = useNavigate();

  const handleOptionClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    option: Option
  ) => {
    e.stopPropagation();

    if (!option.link) {
      e.preventDefault(); // Prevent navigation if no link is provided
    }

    if (option.function) {
      option.function();
    }

    if (option.link) {
      navigate(option.link);
    }
  };
  return (
    <div className={s.container} style={style}>
      {options.map((el: Option, i: number) => (
        <Link
          onClick={(e) => handleOptionClick(e, el)}
          key={`Select-option-map-${i}`}
          to={el.link || "#"}
        >
          {el.name}
        </Link>
      ))}
    </div>
  );
};

export default SelectOption;
