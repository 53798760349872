import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const initial = Array.from({ length: 10 }, (v, k) => k).map((k) => {
  const custom: any = {
    id: `id-${k}`,
    content: `Quote ${k}`,
  };

  return custom;
});

const grid = 8;
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuoteItem = styled.div`
  width: 200px;
  border: 1px solid grey;
  margin-bottom: ${grid}px;
  background-color: lightblue;
  padding: ${grid}px;
`;

function Quote({ quote, index }: any) {
  console.log("Rendering Draggable:", { quote, index }); // Log each draggable

  return (
    <Draggable draggableId={quote.id} index={index}>
      {(provided) => (
        <QuoteItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {quote.content}
        </QuoteItem>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(function QuoteList({ quotes }: any) {
  console.log("Rendering QuoteList with quotes:", quotes); // Log the quotes array

  return quotes.map((quote: any, index: number) => (
    <Quote quote={quote} index={index} key={quote.id} />
  ));
});

function Test() {
  const [state, setState] = useState({ quotes: initial });

  function onDragEnd(result: any) {
    console.log("Drag result:", result); // Log the result of the drag action

    if (!result.destination) {
      console.log("Dropped outside of any droppable area.");
      return;
    }

    if (result.destination.index === result.source.index) {
      console.log("Dropped in the same position. No changes.");
      return;
    }

    console.log(`Moving item from index ${result.source.index} to ${result.destination.index}`);

    const quotes = reorder(state.quotes, result.source.index, result.destination.index);

    console.log("Updated quotes order:", quotes);

    setState({ quotes });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="list"
        isDropDisabled={false}
        isCombineEnabled={false}
        ignoreContainerClipping={false}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <QuoteList quotes={state.quotes} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
export default Test;
