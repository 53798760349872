import React, { useContext, useState } from "react";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";

import Button from "../../../../../../components/Button/Button";
import { fabric } from "fabric";

import { useApiRequest } from "../../../../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../../../../core/context/notifications/NotificationProvider";
import ProjectContext from "../../../../../../core/context/ProjectContext";
import ProductContext from "../../../../../../core/context/ProductContext";
import { convertDataToPostProduct } from "../../../../fabric-functions/POST/ConvertToPostDataProduct";
import addWARect from "../../../../fabric-functions/WorkingAreaLogic/RectWA";
import addWACircle from "../../../../fabric-functions/WorkingAreaLogic/CircleWA";
import saveCanvasJSON from "../../../../fabric-functions/SaveCanvasJSON";
import resetCanvas from "../../../../fabric-functions/ResetCanvas";
import { handleFileInput } from "../../../../fabric-functions/AddToCanvas/HandleFileInput";

const DevMode = () => {
  const { canvasContext, activeGlobalState, setActiveGlobalState, canvasSize } =
    useContext(FabricJSContextNew);
  const { setProjects } = useContext(ProjectContext);
  const { setProducts } = useContext(ProductContext);

  const addMainObject = () => {};
  const notification = useNotification();
  const { sendRequest } = useApiRequest();
  const saveCanvasState = () => {
    const json = canvasContext.toJSON();
    console.log(json);
  };

  const createToImage = () => {
    const test = canvasContext.toImage();
    console.log(test);
  };

  const handleSaveCanvas = () => {
    if (canvasContext) {
      const savedProduct = saveCanvasJSON(canvasContext, "dummy name");
      const formattedJsonString = JSON.stringify(savedProduct, null, 2);
      setJsonOutput(formattedJsonString);
    }
  };

  const [jsonOutput, setJsonOutput] = useState("");
  const [showJSON, setShowJSON] = useState(false);
  const handleConvertedData = () => {
    const readyFormat = convertDataToPostProduct(canvasContext, activeGlobalState);

    const postDataTest = async () => {
      try {
        const response = await sendRequest(
          "post",
          `/creator/2d/templates/${activeGlobalState.fullJSON.id}/batch`,
          readyFormat
        );
        const responsePost = await sendRequest(
          "post",
          `/creator/2d/templates/${activeGlobalState.fullJSON.id}/publish`
        );
        if (responsePost && responsePost.data) {
          const responseData: any = responsePost.data;
          if (responseData) {
            setProducts((prevItems: any) => {
              const itemExists = prevItems.some((item: any) => item.id === responseData.id);

              if (itemExists) {
                return prevItems.map((item: any) =>
                  item.id === responseData.id ? responseData : item
                );
              } else {
                return [...prevItems, responseData];
              }
            });

            setProjects((prevProjects: any) =>
              prevProjects.map((project: any) => ({
                ...project,
                template: project.template.id === responseData.id ? responseData : project.template,
              }))
            );
            setActiveGlobalState((prevState: any) => ({
              ...prevState,
              fullJSON: responseData,
            }));
            notification({
              id: uniqueID,
              type: "SUCCESS",
              message: "Product successfully updated",
            });
          }
          console.log({ response, responsePost });
        }
      } catch (err) {}
    };
    postDataTest();
  };

  function createXAxis(canvasSize: any, zoomLevel: number) {
    const objects = [];
    let majorTickSpacing;
    let minorTickSpacing;

    // Adjust tick spacing based on zoom level
    if (zoomLevel <= 1) {
      majorTickSpacing = 100; // 10 mm = 100 px
      minorTickSpacing = 10; // 1 mm = 10 px
    } else if (zoomLevel <= 2) {
      majorTickSpacing = 10; // 1 mm = 10 px
      minorTickSpacing = 5; // 0.5 mm = 5 px
    } else {
      majorTickSpacing = 5; // 0.5 mm = 5 px
      minorTickSpacing = 1; // 0.1 mm = 1 px
    }

    for (let x = 0; x <= canvasSize.width; x += minorTickSpacing) {
      // Determine if this is a major tick
      const isMajor = x % majorTickSpacing === 0;

      // Create a line for the tick
      const tickLine = new fabric.Line(
        [x, 0, x, isMajor ? 15 : 10], // Longer line for major ticks
        {
          stroke: "black",
          strokeWidth: isMajor ? 1.5 : 1, // Thicker stroke for major ticks
          selectable: false,
          evented: false,
        }
      );
      objects.push(tickLine);

      // Add text labels only for major ticks
      if (isMajor) {
        const mmValue = (x / 10).toFixed(1); // Convert px to mm (1 mm = 10 px)
        const label = new fabric.Text(`${mmValue} mm`, {
          left: x + 2, // Offset to avoid overlap
          top: 15,
          fontSize: 10, // Adjust font size for readability
          selectable: false,
          evented: false,
        });
        objects.push(label);
      }
    }

    return new fabric.Group(objects, {
      selectable: false,
      evented: false,
    });
  }
  // const addXAxis = () => {
  //   const xAxis = createXAxis(canvasSize, 0);
  //   canvasContext.add(xAxis);
  // };
  function createCartesianAxes(canvasSize: { width: number; height: number }, zoomLevel: number) {
    const objects: fabric.Object[] = [];

    // Set the origin to (0, 0)
    const centerX = 0;
    const centerY = 0;

    // Define tick spacings based on zoom level
    let majorTickSpacingPx: number;
    let minorTickSpacingPx: number;

    if (zoomLevel <= 1) {
      majorTickSpacingPx = 100; // 10 mm
      minorTickSpacingPx = 10; // 5 mm
    } else if (zoomLevel <= 2) {
      majorTickSpacingPx = 10; // 5 mm
      minorTickSpacingPx = 5; // 1 mm
    } else {
      majorTickSpacingPx = 5; // 1 mm
      minorTickSpacingPx = 1; // 0.5 mm
    }

    // Helper to convert pixels into mm
    const pxToMm = (px: number) => (px / 10).toFixed(1);

    // Draw the X-axis (horizontal line through the origin)
    const xAxisLine = new fabric.Line(
      [-canvasSize.width / 2, centerY, canvasSize.width / 2, centerY],
      {
        stroke: "black",
        strokeWidth: 1,
        selectable: false,
        evented: false,
      }
    );
    objects.push(xAxisLine);

    // Draw the Y-axis (vertical line through the origin)
    const yAxisLine = new fabric.Line(
      [centerX, -canvasSize.height / 2, centerX, canvasSize.height / 2],
      {
        stroke: "black",
        strokeWidth: 1,
        selectable: false,
        evented: false,
      }
    );
    objects.push(yAxisLine);

    // Function to create X-axis ticks
    function createXTicks() {
      // Add ticks in both positive and negative directions
      for (let x = -canvasSize.width / 2; x <= canvasSize.width / 2; x += majorTickSpacingPx) {
        if (x !== 0) {
          // Create major tick
          const tickLine = new fabric.Line([x, centerY - 10, x, centerY + 10], {
            stroke: "black",
            strokeWidth: 1.5,
            selectable: false,
            evented: false,
            opacity: 0.4,
          });
          objects.push(tickLine);

          // Add label
          const mmValue = pxToMm(Math.abs(x));
          const sign = x < 0 ? "-" : "+";
          const label = new fabric.Text(`${sign}${mmValue} mm`, {
            left: x + 2,
            top: centerY + 12,
            fontSize: 12,
            selectable: false,
            evented: false,
            opacity: 0.7,
          });
          objects.push(label);
        }
      }
    }

    // Function to create Y-axis ticks
    function createYTicks() {
      // Add ticks in both positive and negative directions
      for (let y = -canvasSize.height / 2; y <= canvasSize.height / 2; y += majorTickSpacingPx) {
        if (y !== 0) {
          // Create major tick
          const tickLine = new fabric.Line([centerX - 10, y, centerX + 10, y], {
            stroke: "black",
            strokeWidth: 1.5,
            selectable: false,
            evented: false,
            opacity: 0.4,
          });
          objects.push(tickLine);

          // Add label
          const mmValue = pxToMm(Math.abs(y));
          const sign = y < 0 ? "-" : "+";
          const label = new fabric.Text(`${sign}${mmValue} mm`, {
            left: centerX + 12,
            top: y - 6,
            fontSize: 12,
            selectable: false,
            evented: false,
            opacity: 0.7,
          });
          objects.push(label);
        }
      }
    }

    // Generate ticks for X and Y axes
    createXTicks();
    createYTicks();

    // Return the complete Cartesian coordinate system as a group
    return new fabric.Group(objects, {
      selectable: false,
      evented: false,
      level: 6,
    });
  }

  // Usage
  const zoomLevel = 0;

  function addCartesianAxes() {
    const axes = createCartesianAxes(canvasSize, zoomLevel);
    canvasContext.add(axes);
  }

  return (
    <div>
      <button onClick={addMainObject}>add main object</button>
      <button onClick={() => addCartesianAxes()}>add x axis</button>
      {/* <button style={{ marginTop: "30px" }} onClick={handleAddWARect}>
        Create Workable Area
      </button>
      <button style={{ marginTop: "30px" }} onClick={handleAddWACircle}>
        Create Workable Area Circle
      </button> */}
      {/* <button onClick={handleSaveCanvas}>save working area</button>
      <button onClick={() => resetCanvas(canvasContext)}>Clear canvas</button>
      <button onClick={createToImage}>create to image</button>
      <button onClick={saveCanvasState}>save canvas state</button>
      <Button
        text="choose main image"
        type="input"
        onFileChange={(e) => handleFileInput(1, canvasContext, e, undefined, undefined, undefined)}
      />
      <button onClick={() => setShowJSON(!showJSON)}>show json</button>
      <button onClick={handleConvertedData}>converted data to send</button> */}

      {showJSON ? (
        <div style={{ display: "flex", overflow: "hidden" }}>
          <pre>{jsonOutput}</pre>
        </div>
      ) : null}
    </div>
  );
};

export default DevMode;
