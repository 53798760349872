import { InputAdornment, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import s from "./Graphics.module.scss";

import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useApiRequest } from "../../../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../../../core/context/notifications/NotificationProvider";
import { useTranslation } from "react-i18next";
import addGateway from "../../../../../features/Fabric-JS/fabric-functions/AddToCanvas/AddGateway";
import { generateRandomHexString } from "../../../../../helper/generateRandomHexString";
import ConvertToVectorModal from "../../../../Modals/ConvertToVector/ConvertToVectorModal";
import ModalWrapper from "../../../../Modals/Modal.wrapper";
import NormalModal from "../../../../Modals/Modal/NormalModal";
import Button from "../../../../Button/Button";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import GraphicComponent from "../../../../GraphicComponent/GraphicComponent";
import AuthContext from "../../../../../core/context/AuthContext";
import { calculateDIP } from "../../../../../helper/functions";
import { ReactComponent as SearchLoop } from "../../../../../assets/icons/loop.svg";
import FolderTreeStructure from "../../../../FolderComponents/FolderTreeStructure/FolderTreeStructure";
import ContentLeftBar from "../components/ContentLeftBar/ContentLeftBar";
import { textAlign } from "@mui/system";
const buttonStyles: React.CSSProperties = {
  marginLeft: "auto",
  width: "100%",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 700,
  color: "#575757",
  justifyContent: "center",
};
const noBacgroundComponent = {
  background: "transparent",
};
interface IGraphics {
  url: string;
  fileType: string;
  name: string;
}

const GraphicsLeftBar = () => {
  const [age, setAge] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const { canvasContext, setCanvasObjectList, activeGlobalState } = useContext(FabricJSContextNew);
  const { sendRequest } = useApiRequest();
  const notification = useNotification();
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [addedImages, setAddedImages] = useState<any>([]);
  const [fileToUpload, setFileToUpload] = useState<any>(null);
  const [askVectorModal, setAskVectorModal] = useState<boolean>(false);
  const levelBased = activeGlobalState.canvasType === "templates" ? 1 : 4;
  const { user } = useContext(AuthContext);

  const [fetchGraphic, setFetchGraphics] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<any>({ id: 0 });

  const levelBasedOnCanvasType =
    activeGlobalState.canvasType === "templates"
      ? 1
      : activeGlobalState.canvasType === "projects"
      ? 4
      : null;

  // --------------------------
  // Upload file and get server URL
  // --------------------------
  const sendFileHandler = async (fileData: any) => {
    if (!user) {
      // If user is not logged in, you might just return a base64 approach or skip uploading
      return null;
    }

    const formData = new FormData();
    formData.append("attachment", fileData.file);
    formData.append("draft_id", fileData.draft_id);

    try {
      const response: any = await sendRequest("post", "/upload-pending-attachment", formData);
      if (!response) {
        notification({
          id: uniqueID,
          type: "ERROR",
          message: "USP something went wrong",
        });
        return null;
      }

      // If success, return the response
      setImageUrl(response.url);
      setAddedImages((prevImages: any) => [
        ...prevImages,
        { url: response.url, name: fileData?.name || "new image", fileType: "img" },
      ]);

      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Graphic has been uploaded",
      });

      setTimeout(() => {
        setFetchGraphics((prevState) => !prevState);
      }, 1000);

      return response;
    } catch (err) {
      console.error(err);
      notification({
        id: uniqueID,
        type: "ERROR",
        message: "USP something went wrong",
      });
      return null;
    }
  };

  // --------------------------
  // Main function to handle file input -> upload -> add to canvas
  // --------------------------
  const createGraphic = async (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }
    setFileToUpload(file);

    // If no user, simply add as base64
    if (!user) {
      handleLocalFile(file);
      return;
    }

    // 1) Send file to the server first -> get final URL
    const draft_id = generateRandomHexString(32);
    const fileData = { file, draft_id, name: file.name };

    const response = await sendFileHandler(fileData);
    if (!response || !response.url) {
      return;
    }
    // 2) Once we have the server URL, read local file just to extract dimension or DPI metadata
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.onload = async () => {
        const width = img.width;
        const height = img.height;

        // Add graphic from server URL
        const constructImageToAddInfo = {
          url: response.url, // <--- Use the server-returned URL
          name: file.name || "new image",
          fileType: "img",
          width,
          height,
          level: levelBased,
        };

        const addedImage = await addGateway(
          canvasContext,
          constructImageToAddInfo,
          setCanvasObjectList
        );
        const workingArea = canvasContext?.getObjects()?.find((el: any) => el.level === 6);
        if (workingArea && addedImage) {
          const { isDPIWidthTooLow, isDPIHeightTooLow, isDpiSufficient } = calculateDIP(
            addedImage,
            workingArea?.dpi || 300,
            notification
          );
          console.log({ isDPIWidthTooLow, isDPIHeightTooLow });
          addedImage.set({
            isDpiSufficient,
          });
          addedImage.setCoords();
          canvasContext.renderAll();
        }
      };
      if (event.target?.result) {
        img.src = event.target.result as string;
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };

  // --------------------------
  // Fallback logic for when user is not logged in
  // (i.e., skipping server upload, using base64)
  // --------------------------
  const handleLocalFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = async () => {
        const width = img.width;
        const height = img.height;
        const dpi = 72; // Or any logic for DPI

        const constructImageToAddInfo = {
          url: event.target?.result as string, // base64
          name: file.name || "new image",
          fileType: "img",
          width,
          height,
          dpi,
          level: 4,
        };
        addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);
      };
      if (event.target?.result) {
        img.src = event.target.result as string;
      }
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };

  // --------------------------
  // Handle existing images from the server
  // --------------------------
  const handleAddGraphics = (el: IGraphics) => {
    const graphcsInfo = {
      level: levelBasedOnCanvasType,
      fileType: "img",
      url: el.url,
      name: el.name,
    };
    addGateway(canvasContext, graphcsInfo, setCanvasObjectList);
  };

  const handleAddText = (el: any) => {
    const textinfo = {
      fontSize: el?.style?.fontSize || 16,
      text: el.text,
      color: el?.style?.color || "#000000",
      fileType: "text",
      level: levelBasedOnCanvasType,
    };
    addGateway(canvasContext, textinfo, setCanvasObjectList);
  };

  // For vector conversion (unchanged logic if needed)
  const handleVectorModalClose = (option: string) => {
    setAskVectorModal(false);
    const draft_id = generateRandomHexString(32);
    const fileData = { file: fileToUpload, draft_id, name: fileToUpload?.name };

    if (option === "Yes") {
      sendFileToConvertHandler(fileData);
    } else {
      sendFileHandler(fileData);
    }
  };

  const sendFileToConvertHandler = async (fileData: any) => {
    // Example logic if you’re converting to vector
    try {
      const formData = new FormData();
      formData.append("file", fileData.file);
      const response = await sendRequest("post", "http://localhost:3000/convert", formData);
      if (!response) {
        notification({
          id: uniqueID,
          type: "ERROR",
          message: "USP something went wrong",
        });
        return null;
      }
      setImageUrl(response.url);
      setAddedImages((prevImages: any) => [
        ...prevImages,
        { url: response.url, name: fileData?.name || "converted image", fileType: "img" },
      ]);

      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Graphic has been converted and added",
      });
      return response;
    } catch (err) {
      console.error(err);
      notification({
        id: uniqueID,
        type: "ERROR",
        message: "USP something went wrong",
      });
      return null;
    }
  };

  return (
    <>
      <div className={s.container}>
        <Button
          text={t("Select file")}
          type="input"
          onFileChange={(e) => createGraphic(e)}
          styles={buttonStyles}
        />
        <TextField
          label={t("Search")}
          id="outlined-basic"
          variant="outlined"
          sx={{ marginBottom: "-10px" }}
          fullWidth
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchLoop />
              </InputAdornment>
            ),
          }}
        />
        <FolderTreeStructure
          requestPath="projects"
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
        />
        <div className={s.list_container}>
          <span>{t("LastUsed")}</span>

          <FilterComponent
            endpoint="/media"
            defaultSort="-id" // Sort in descending order by ID to get the most recent items
            defaultPagination={3} // Limit to 3 items per request
            isPagination={false} // Disable pagination UI if not needed
            forceFetch={fetchGraphic}
            render={({ data, onDelete }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <GraphicComponent
                    key={`dashboard-product-products-all-map${i}`}
                    imgSrc={el.url}
                    variant="3ex_small"
                    handleClick={() => handleAddGraphics(el)}
                    id={`dashboard-product-products-all-map${i}`}
                    onDelete={onDelete}
                    graphic={el}
                    componentOptions={"2"}
                  />
                ))}
              </div>
            )}
          />
        </div>
        {selectedFolder.label === "root" ? (
          <ContentLeftBar
            folderRequestPath="media"
            filterComponentRequestPath="media"
            setSelectedFolder
          />
        ) : (
          <div className={s.list_container}>
            <FilterComponent
              endpoint="/media"
              defaultSort="id"
              defaultPagination={10}
              isPagination={true}
              category_id={selectedFolder?.id}
              forceFetch={fetchGraphic}
              render={({ data, onDelete }) => (
                <div className={s.map_container}>
                  {data?.map((el: any, i: number) => (
                    <GraphicComponent
                      key={`dashboard-product-products-all-map${i}`}
                      imgSrc={el.url}
                      variant="2ex_small"
                      handleClick={() => {}}
                      id={`dashboard-product-products-all-map${i}`}
                      onDelete={onDelete}
                      graphic={el}
                      componentOptions={"2"}
                    />
                  ))}
                </div>
              )}
            />
          </div>
        )}
      </div>

      {/* Vector Modal Example */}
      <NormalModal open={askVectorModal} setOpen={setAskVectorModal}>
        <ModalWrapper setOpen={setAskVectorModal}>
          <ConvertToVectorModal
            setCloseModal={setAskVectorModal}
            handleOptionSelect={handleVectorModalClose}
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default GraphicsLeftBar;
