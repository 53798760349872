export const convertDataToPostProject = (canvasContext: any, activeGlobalState: any) => {
  console.log(activeGlobalState, "ACTIVE STATE FUNCTION");

  if (canvasContext) {
    // Custom serialization to exclude clipPath from all objects on the canvas

    const customToJSON = canvasContext.toJSON.bind(canvasContext);

    canvasContext.toJSON = (propertiesToInclude: any) =>
      customToJSON(propertiesToInclude).objects.map((obj: any) => {
        const { clipPath, ...rest } = obj; // Destructure to exclude clipPath
        return rest;
      });

    const canvasJson = canvasContext.toJSON([
      "id",
      "name",
      "type",
      "opacity",
      "level",
      "selectable",
      "lockMovementX",
      "lockMovementY",
      "lockScalingX",
      "lockScalingY",
      "lockRotation",
      "hasControls",
      "guid",
      "uuid",
      "isClone",
      "order",
      "dpi",
      "originalWidth",
      "originalHeight",
      "dpiWidth",
      "dpiHeight",
      "isDpiSufficient",
    ]);

    // Resetting toJSON to original function to avoid side effects
    canvasContext.toJSON = customToJSON;

    // Map UUIDs to IDs from activeGlobalState.fullJSON.layers for quick lookup and ID assignment
    const layerIdMap = new Map();

    activeGlobalState?.fullJSON?.layers?.forEach((layer: any) => {
      layerIdMap.set(layer.uuid, layer.id);
    });

    const filteredCanvasJson = canvasJson.filter(
      (obj: any) => !obj?.isClone && obj?.level !== 1 && obj.level !== 2 && obj.level !== 6
    );

    // Prepare new or updated layers
    const readyFormat = filteredCanvasJson.map((obj: any) => {
      const isLayerExist = layerIdMap.has(obj.guid || obj.uuid);
      const eventName = isLayerExist ? "layer_update" : "layer_create";

      return {
        event: eventName,
        payload: {
          id: obj.id,
          uuid: obj.guid || obj.uuid,

          view_uuid: activeGlobalState?.selectedView?.view_uuid,
          version_uuid: activeGlobalState?.selectedView?.uuid,
          is_locked: false,
          is_visible: false,
          order: obj.order || 0,
          name: obj.name || "",
          type: "text",
          json: obj,
          variation_uuid: activeGlobalState.fullJSON.templates?.variation[0]?.uuid || "fake",
        },
      };
    });

    console.log({ readyFormat });

    // Prepare deleted layers
    const canvasUUIDs = new Set(filteredCanvasJson.map((obj: any) => obj.guid || obj.uuid));
    console.log({ canvasUUIDs });

    const deletedLayers = activeGlobalState.fullJSON.layers
      .filter(
        (layer: any) =>
          !canvasUUIDs.has(layer.uuid) &&
          layer.version_uuid === activeGlobalState?.selectedView?.uuid // Ensure only layers from the current view are considered
      )
      .map((layer: any) => ({
        event: "layer_delete",
        payload: {
          id: layer.id,
          uuid: layer.uuid,
          ...(activeGlobalState.canvasType === "project"
            ? { version_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          view_uuid: activeGlobalState?.selectedView?.view_uuid,
          is_locked: false,
          is_visible: false,
          order: layer.order || 0,
          name: layer.name || "",
          json: layer,
        },
      }));

    console.log({ deletedLayers });

    // Prepare overprint working area
    const workingAreaObj = filteredCanvasJson.find((obj: any) => obj.level === 6);
    let overprintEvent = null;
    if (workingAreaObj) {
      const isWorkingAreaExist = layerIdMap.has(workingAreaObj.guid || workingAreaObj.uuid);
      const overprintEventName = isWorkingAreaExist ? "overprint_update" : "overprint_create";
      overprintEvent = {
        event: overprintEventName,
        payload: {
          type: null,
          uuid: workingAreaObj.guid || workingAreaObj.uuid,
          variation_uuid: "any",
          ...(activeGlobalState.canvasType === "projects"
            ? { view_uuid: activeGlobalState?.selectedView?.uuid }
            : {}),
          json: workingAreaObj,
          dpi: 300,
          width: workingAreaObj.width,
          height: workingAreaObj.height,
        },
      };
    }
    const isValidThumbnail =
      activeGlobalState?.generateThumbnail && activeGlobalState.generateThumbnail !== "data:,";

    let result = [];

    // Only add thumbnail_regenerate event if valid
    if (isValidThumbnail) {
      const thumbnailRegenerateEvent = {
        event: "thumbnail_regenerate",
        payload: activeGlobalState.generateThumbnail,
      };

      const viewUpdateEvent = {
        event: "version_update",
        payload: {
          thumbnail: activeGlobalState.generateThumbnail,
          uuid: activeGlobalState?.selectedView?.uuid,
          ...(activeGlobalState.fullJSON.versions.length === 1
            ? { view_uuid: activeGlobalState?.selectedView?.view_uuid }
            : {}),
        },
      };

      // Add these events only if thumbnail is valid
      result.push(thumbnailRegenerateEvent, viewUpdateEvent);
    }

    // Add other events unconditionally
    result.push(...readyFormat, ...deletedLayers);

    if (overprintEvent) {
      result.push(overprintEvent);
    }

    console.log({ result }, "RESULTS");
    return result;
  }
};
