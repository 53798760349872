const sortCanvasObjectsByLevel = (canvas: fabric.Canvas) => {
  if (!canvas) return;

  const objects = canvas.getObjects();

  objects.sort((a: any, b: any) => {
    const levelA = typeof a.level === "number" ? a.level : Number.MAX_VALUE;
    const levelB = typeof b.level === "number" ? b.level : Number.MAX_VALUE;

    if (levelA !== levelB) {
      return levelA - levelB;
    }
    const orderA = typeof a.order === "number" ? a.order : Number.MAX_VALUE;
    const orderB = typeof b.order === "number" ? b.order : Number.MAX_VALUE;
    return orderA - orderB;
  });

  objects.forEach((obj: any, index: number) => {
    canvas.moveTo(obj, index);
  });

  canvas.requestRenderAll();
};

export default sortCanvasObjectsByLevel;
