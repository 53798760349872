import React, { useContext, useEffect, useState } from "react";
import s from "./WorkShopProduct.module.scss";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";

import RenderLayers from "./components/RenderLayers";
import DevMode from "./DevMode";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import AuthContext from "../../../../../../core/context/AuthContext";
import addBorderRadiusToWARect from "../../../../fabric-functions/WorkingAreaLogic/BorderRadiousRect";
import addWARect from "../../../../fabric-functions/WorkingAreaLogic/RectWA";
import addWACircle from "../../../../fabric-functions/WorkingAreaLogic/CircleWA";
import FolderTreeStructure from "../../../../../../components/FolderComponents/FolderTreeStructure/FolderTreeStructure";

const WorkShopProduct = () => {
  const { t } = useTranslation();
  const { canvasContext, activeGlobalState, setActiveGlobalState } = useContext(FabricJSContextNew);
  const { user } = useContext(AuthContext);
  const handleBorderRadious = (e: any) => {
    addBorderRadiusToWARect(canvasContext, e.target.value);
  };
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [radius, setRadius] = useState(0);

  const [unit, setUnit] = useState("MM");
  const [dpi, setDpi] = useState(0);

  useEffect(() => {
    const workingArea = activeGlobalState?.workingArea?.json;

    if (workingArea) {
      // Synchronize local state with the working area's properties
      setWidth(workingArea?.dpiWidth || 0);
      setHeight(workingArea?.dpiHeight || 0);
      setDpi(workingArea.dpi);
      // setDpi(workingArea.radius || 0);
    }
  }, [activeGlobalState]); // Only re-run when the working area changes

  const updateWorkingAreaSizeCIRCLE = (newRadius: number, newDpi: number) => {
    const workingArea = canvasContext?.getObjects()?.find((el: any) => el.level === 6);
    if (workingArea) {
      workingArea.set({
        width: newRadius * 2,
        height: newRadius * 2,
        radius: newRadius,
        dpi: newDpi,
      });

      console.log("Canvas Working Area Updated:", workingArea);

      // setActiveGlobalState((prevState: any) => {
      //   const updatedOverprints = prevState.fullJSON.overprints.map((overprint: any) => {
      //     if (overprint.uuid === workingArea.uuid) {
      //       console.log("Overprint Updated:", {
      //         ...overprint,
      //         dpiWidth: width,
      //         dpiHeight: height,
      //         dpi: newDpi,
      //       });
      //       return {
      //         ...overprint,
      //         dpiWidth: width,
      //         dpiHeight: height,
      //         dpi: newDpi,
      //       };
      //     }
      //     return overprint;
      //   });

      //   const newState = {
      //     ...prevState,
      //     fullJSON: {
      //       ...prevState.fullJSON,
      //       overprints: updatedOverprints,
      //     },
      //     workingArea: {
      //       ...prevState.workingArea,
      //       json: {
      //         ...prevState.workingArea?.json,
      //         dpiWidth: width,
      //         dpiHeight: height,
      //         dpi: newDpi,
      //       },
      //     },
      //   };

      //   console.log("New Global State:", newState);
      //   return newState;
      // });

      canvasContext.renderAll();
    }
  };
  const updateWorkingAreaSizeRECT = (newWidth: number, newHeight: number, newDpi: number) => {
    const workingArea = canvasContext?.getObjects()?.find((el: any) => el.level === 6);

    if (workingArea) {
      // Reset scaling and update dimensions
      workingArea.set({
        dpiWidth: newWidth,
        dpiHeight: newHeight,
        scaleX: 1,
        scaleY: 1,
        dirty: true,
        dpi: newDpi,
      });

      // Update bounding box
      workingArea.setCoords();

      // Mark object as dirty and re-render canvas
      workingArea.dirty = true;
      canvasContext.renderAll();

      // // Update global state
      // setActiveGlobalState((prevState: any) => {
      //   const updatedOverprints = prevState.fullJSON.overprints.map((overprint: any) => {
      //     if (overprint.uuid === workingArea.uuid) {
      //       return {
      //         ...overprint,
      //         dpiWidth: newWidth,
      //         dpiHeight: newHeight,
      //         dpi: newDpi,
      //       };
      //     }
      //     return overprint;
      //   });

      //   return {
      //     ...prevState,
      //     fullJSON: {
      //       ...prevState.fullJSON,
      //       overprints: updatedOverprints,
      //     },
      //     workingArea: {
      //       ...prevState.workingArea,
      //       json: {
      //         ...prevState.workingArea?.json,
      //         dpiWidth: newWidth,
      //         dpiHeight: newHeight,
      //         dpi: newDpi,
      //       },
      //     },
      //   };
      // });

      console.log("Canvas Working Area Updated:", workingArea);
    }
  };

  const handleUnitChange = (event: SelectChangeEvent) => {
    setUnit(event.target.value as string);
  };

  const setInputHanlder = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    const newName = value;

    setActiveGlobalState((prevState: any) => {
      return {
        ...prevState,
        fullJSON: {
          ...prevState.fullJSON,
          name: name === "productName" ? newName : prevState.fullJSON.name,
        },
        selectedView: {
          ...prevState.selectedView,
          name: name === "viewName" ? newName : prevState.selectedView.name,
        },
      };
    });
  };

  const [choosenFolder, setChoosenFolder] = useState<number>(0);
  const [dev, setDev] = useState(false);

  return (
    <div className={s.container}>
      <div className={s.ex_container}>
        <div className={s.info}>
          <TextField
            name="productName"
            size="small"
            label={t("Name")}
            value={activeGlobalState?.fullJSON?.name}
            onChange={(e) => setInputHanlder(e)}
          />
          <FolderTreeStructure requestPath="templates" setSelectedFolder={setChoosenFolder} />
          <h4>{t("PageSettings").toUpperCase()}</h4>
        </div>
        <div className={s.top}>
          <div className={s.top_container}>
            <div className={s.img_container}>
              <img src={activeGlobalState.generateThumbnail} />
            </div>
            {user ? (
              <TextField
                name="viewName"
                size="small"
                label={t("WorkShopProduct.Name")}
                value={activeGlobalState?.selectedView?.name || ""}
                onChange={(e) => setInputHanlder(e)}
              />
            ) : null}
          </div>
        </div>

        <div className={s.middle}>
          <h4>{t("WorkShopProduct.Print area")}</h4>
          <div>
            <span>{t("WorkShopProduct.Shape")}</span>
            <div className={s.shape_container} onClick={() => addWARect(canvasContext)}>
              <CropSquareIcon fontSize="large" />
            </div>
            <div className={s.shape_container} onClick={() => addWACircle(canvasContext)}>
              <RadioButtonUncheckedIcon fontSize="large" />
            </div>
          </div>
          {user ? (
            <div className={s.input_container}>
              <TextField
                size="small"
                label="Width"
                name="width"
                value={width}
                onChange={(e) => {
                  const newWidth = Number(e.target.value);
                  setWidth(newWidth); // Update local state
                  updateWorkingAreaSizeRECT(newWidth, height, dpi); // Update canvas and global state
                }}
              />
              <TextField
                size="small"
                label="Height"
                name="height"
                value={height}
                onChange={(e) => {
                  const newHeight = Number(e.target.value);
                  setHeight(newHeight); // Update local state
                  updateWorkingAreaSizeRECT(width, newHeight, dpi); // Update canvas and global state
                }}
              />
              {activeGlobalState?.workingArea?.json?.type === "circle" ? (
                <TextField
                  size="small"
                  label="Radius"
                  name="radius"
                  value={radius}
                  onChange={(e) => {
                    const newRadius = Number(e.target.value);
                    setRadius(newRadius); // Update local state
                    updateWorkingAreaSizeCIRCLE(newRadius, dpi); // Update canvas and global state
                  }}
                />
              ) : null}
              <FormControl fullWidth>
                <InputLabel id="unit-select-label">Unit</InputLabel>
                <Select
                  labelId="unit-select-label"
                  id="unit-select"
                  value={unit}
                  label="Unit"
                  size="small"
                  onChange={handleUnitChange}
                >
                  <MenuItem value="MM">MM</MenuItem>
                  <MenuItem value="INCHES">{t("Inches")}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                size="small"
                label="DPI"
                value={dpi}
                onChange={(e) => {
                  const newDpi = Number(e.target.value);
                  setDpi(newDpi); // Update local state
                  updateWorkingAreaSizeRECT(width, height, newDpi); // Update canvas and global state
                }}
              />
              <TextField
                size="small"
                label={t("WorkShopProduct.Corner radious")}
                type="number"
                onChange={handleBorderRadious}
              />
            </div>
          ) : null}
        </div>
        <div className={s.bottom}>
          <h4>{t("WorkShopProduct.Layers")}</h4>

          <RenderLayers />
        </div>

        <button onClick={() => setDev(!dev)} style={{ marginTop: "auto", width: "10%" }}>
          DEV
        </button>
        {dev ? <DevMode /> : null}
      </div>
    </div>
  );
};

export default WorkShopProduct;
