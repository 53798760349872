import { ReactComponent as DashboardIcon } from "../../../assets/svg/Dashboard.svg";
import { ReactComponent as DesignIcon } from "../../../assets/svg/Design.svg";
import { ReactComponent as MediaIcon } from "../../../assets/svg/Media.svg";
import { ReactComponent as ProjectsIcon } from "../../../assets/svg/Projects.svg";
import { ReactComponent as ProductsIcon } from "../../../assets/svg/Products.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/svg/Settings.svg";
import { ReactComponent as ManageIcon } from "../../../assets/svg/ManageTheSite.svg";
import { ReactComponent as SubscriptionsIcon } from "../../../assets/svg/Subscription.svg";
import { ReactComponent as NotificationsIcon } from "../../../assets/svg/Notifications.svg";
import { ReactComponent as TextIcon } from "../../../assets/svg/Subscription.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/Trash.svg";
import { TNavigationItem, TIconMap } from "./type";

export const navigationMenu = (curentProjectLink: string): TNavigationItem[] => [
  // {
  //   name: "Dashboard",
  //   link: "/dashboard",
  //   isLink: true,
  //   ava: "dashboard",
  // },
  {
    name: "Quick menu",
    isLink: false,
    children: [
      {
        name: "Design",
        link: `/design-studio`,
        isLink: true,
        ava: "design",
      },
      {
        name: "LastUsed",
        link: `/${curentProjectLink}`,
        isLink: true,
        ava: "design",
      },
    ],
  },
  {
    name: "Library",
    isLink: false,
    children: [
      {
        name: "Products",
        link: "/products",
        biz: true,
        isLink: true,
        ava: "products",
        hasFolderStructure: true,
      },
      {
        name: "Projects",
        link: "/projects",
        isLink: true,
        ava: "projects",
        hasFolderStructure: true,
      },
      {
        name: "Media (files)",
        link: "/media",
        isLink: true,
        ava: "media",
      },
      // {
      //   name: "Text",
      //   link: "/text",
      //   isLink: true,
      //   ava: "text",
      // },
      // {
      //   name: "Bin",
      //   link: "/bin",
      //   isLink: true,
      //   ava: "bin",
      // },
    ],
  },
  {
    name: "My site",
    isLink: false,
    children: [
      {
        name: "Manage site",
        link: "/manage",
        isLink: true,
        ava: "manage",
      },
    ],
  },
  {
    name: "My account",
    isLink: false,
    children: [
      {
        name: "Notifications",
        link: "/notifications",
        isLink: true,
        ava: "notifications",
      },
      {
        name: "Settings",
        link: "/settings/profile",
        isLink: true,
        ava: "options",
      },
      {
        name: "Subscription",
        link: "/subscription/managment",
        isLink: true,
        ava: "subscriptions",
      },
    ],
  },
];

export const iconMap: any = {
  dashboard: DashboardIcon,
  design: DesignIcon,
  products: ProductsIcon,
  projects: ProjectsIcon,
  media: MediaIcon,
  text: TextIcon,
  bin: TrashIcon,
  manage: ManageIcon,
  notifications: NotificationsIcon,
  options: SettingsIcon,
  subscriptions: SubscriptionsIcon,
};
