import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#548ad3", // Main app color
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            fontFamily: "Akrobat",
            fontWeight: 500,
            paddingInline: "5px 18px",
            "& fieldset": {
              borderColor: "#E6E6E6",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `0.89rem`,
          },
        },
      },
    },
  },
});
