import { fabric } from "fabric";

export const serializeObject = (activeObject: fabric.Object | null): any | null => {
  if (!activeObject) return null;

  // Ensure the object has an 'id' property
  const objId = (activeObject as any).id;
  if (!objId) {
    console.warn("Active object does not have an 'id'. Assigning a unique id.");
    activeObject.set("id", `${activeObject.type}-${Date.now()}`);
  }

  // Shared properties for all object types
  const baseProperties = {
    width: activeObject.width || 0,
    height: activeObject.height || 0,
    dpi: activeObject.dpi || 0,
    id: (activeObject.id as string) || `${activeObject.type}-${Date.now()}`,
    name: (activeObject as any).name || "",
    type: activeObject.type || "",
    opacity: activeObject.opacity || 1,
    level: (activeObject as any).level || 0,
    selectable: activeObject.selectable || false,
    lockMovementX: activeObject.lockMovementX || false,
    lockMovementY: activeObject.lockMovementY || false,
    lockScalingX: activeObject.lockScalingX || false,
    lockScalingY: activeObject.lockScalingY || false,
    lockRotation: activeObject.lockRotation || false,
    hasControls: activeObject.hasControls || false,
    hasBorders: activeObject.hasBorders || false,
    padding: activeObject.padding || 0,
    transparentCorners: activeObject.transparentCorners || false,
    borderColor: activeObject.borderColor || "#000000",
    cornerColor: activeObject.cornerColor || "#000000",
    cornerStyle: activeObject.cornerStyle || "rect",
    cornerSize: activeObject.cornerSize || 0,
    lockScalingFlip: activeObject.lockScalingFlip || false,
    uuid: (activeObject as any).uuid || "",
    guid: (activeObject as any).guid || "",
    isClone: (activeObject as any).isClone || false,
    clipPath: activeObject.clipPath ? activeObject.clipPath.toObject() : null,
    order: (activeObject as any).order || 0,
    left: activeObject.left || 0,
    top: activeObject.top || 0,
    angle: activeObject.angle || 0,
    scaleX: activeObject.scaleX || 1,
    scaleY: activeObject.scaleY || 1,
    flipX: activeObject.flipX || false,
    flipY: activeObject.flipY || false,
    visible: activeObject.visible !== undefined ? activeObject.visible : true,
    originalWidth: activeObject.originalWidth,
    originalHeight: activeObject.originalHeight,
  };

  // Additional properties for specific object types
  switch (activeObject.type) {
    case "i-text":
      const textObj = activeObject as fabric.IText;
      return {
        ...baseProperties,
        text: textObj.text || "",
        fontSize: textObj.fontSize || 20,
        fontWeight: textObj.fontWeight || "normal",
        fontStyle: textObj.fontStyle || "normal",
        textAlign: textObj.textAlign || "left",
        underline: textObj.underline || false,
        fontFamily: textObj.fontFamily || "Times New Roman",
        fill: textObj.fill || "#000000",
        stroke: textObj.stroke || null,
        strokeWidth: textObj.strokeWidth || 1,
        lineHeight: textObj.lineHeight || 1.2,
        charSpacing: textObj.charSpacing || 0,
        textBackgroundColor: textObj.textBackgroundColor || "",
        styles: textObj.styles || [],
      };

    case "image":
      const imageObj = activeObject as fabric.Image;
      return {
        ...baseProperties,
        src: imageObj.getSrc() || "",
        cropX: imageObj.cropX || 0,
        cropY: imageObj.cropY || 0,
        crossOrigin: imageObj.crossOrigin || null,
        filters: imageObj.filters || [],
      };

    default:
      // For other types, return only base properties
      return baseProperties;
  }
};
