import React, { useContext, useEffect, useState } from "react";
import s from "./Products.module.scss";
import ProductComponent from "../../../../ProductComponent/ProductComponent";
import ProductContext from "../../../../../core/context/ProductContext";
import FolderNodeTree from "../../../../FolderComponents/FolderNodeThree/FolderNodeTree";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import AppContext from "../../../../../core/context/AppContext";
import ProjectComponent from "../../../../ProjectComponent.tsx/ProjectComponent";

import Breadcrumbs from "../../../../Nav/Breadcrumbs/Breadcrumbs";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";

interface IProducts {
  setTempActiveGlobalState: any;
  handleDoubleClick?: () => void;
}

const Products = ({
  setTempActiveGlobalState,
  handleDoubleClick,
}: IProducts) => {
  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);
  const [folderPath, setFolderPath] = useState<any[]>([]);
  const { setStyleIsActive } = useContext(FabricJSContextNew);
  //temp state is used to store state befor eit is accepted to editor

  useEffect(() => {
    if (folderPath.length > 0) {
      const lastFolderInArray: number = folderPath[folderPath.length - 1]?.id;

      setCurrentFolderId(lastFolderInArray);
    } else {
      setCurrentFolderId(null);
    }
  }, [folderPath]);

  const handleClick = (selectedView: any, template: any) => {
    setStyleIsActive(template?.id);
    setTempActiveGlobalState({
      fullJSON: template,
      selectedView: selectedView,
    });
  };

  return (
    <div className={s.container}>
      <div className={s.folder_div}>
        <Breadcrumbs
          styleOption={2}
          folderPath={folderPath}
          setFolderPath={setFolderPath}
          type="folder"
        />
        <div className={s.folder_node_container}>
          <FolderNodeTree
            requestPath="templates"
            folderPath={folderPath}
            setFolderPath={setFolderPath}
            variant="2xl_small"
          />
        </div>
      </div>
      <div className={s.product_list}>
        <FilterComponent
          endpoint="/creator/2d/templates"
          defaultSort="id"
          defaultPagination={5}
          category_id={currentFolderId?.toString()}
          isPagination={true}
          enableSordByDate={false}
          render={({ data }) => (
            <div className={s.map_container}>
              {data?.map((el: any, i: number) => (
                <ProductComponent
                  key={`dashboard-projects-projects-all-map${i}`}
                  product={el}
                  variant="ex_small"
                  handleClick={(variant) => handleClick(variant, el)}
                  id={el?.id}
                  componentOptions="1"
                  handleDoubleClick={handleDoubleClick}
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Products;
