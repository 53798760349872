import React, { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Graphics from "./Graphics/TopToolsGraphics";
import Text from "./Text/TopToolsText";
import NoElement from "./NoElement/NoElement";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";
import LoadingState from "../../../../../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";

interface IRenderAction {
  isLoading?: boolean;
}

const RenderAction = ({ isLoading }: IRenderAction) => {
  const [action, setAction] = useState("noElement");
  const { canvasActiveObject } = useContext(FabricJSContextNew);
  const { t } = useTranslation();

  useEffect(() => {
    if (canvasActiveObject && canvasActiveObject?.objectJSON) {
      const typeOfElement = canvasActiveObject?.objectJSON?.id?.split("-")[1];
      if (canvasActiveObject.objectJSON.level === 6) {
        setAction("workingArea");
      } else if (typeOfElement) {
        setAction(typeOfElement);
      } else {
        setAction("noElement");
      }
    } else {
      setAction("noElement");
    }
  }, [canvasActiveObject]);

  const actions: Record<string, JSX.Element> = {
    img: <Graphics />,
    text: <Text />,
    workingarea: <NoElement />,
    noElement: <NoElement />,
  };

  if (isLoading) {
    return <LoadingState text={t("Saving")} styles={{ width: "400px" }} />;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={action} // Ensures unique key for each state
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2 }}
      >
        {actions[action]}
      </motion.div>
    </AnimatePresence>
  );
};

export default RenderAction;
