import React, { useRef, useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import s from "./TopTools.wrapper.module.scss";

import RenderAction from "./RenderAction";
import AppContext from "../../../../../../core/context/AppContext";

const TopTools = () => {
  const { isLoadingSave } = useContext(AppContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setContainerWidth(containerRef.current?.scrollWidth);
      });
      resizeObserver.observe(containerRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <div className={s.wrapper}>
      <motion.div
        className={s.container}
        style={{ width: "fit-content" }}
        animate={{ width: containerWidth ? `${containerWidth}px` : "fit-content" }}
        transition={{ duration: 0.25, ease: "easeOut" }}
      >
        <div ref={containerRef} className={s.innerContainer}>
          <RenderAction isLoading={isLoadingSave} />
        </div>
      </motion.div>
    </div>
  );
};

export default TopTools;
