import React, { useContext } from "react";
import s from "./ComponentBackground.module.scss";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";

interface IComponentBackground {
  children: React.ReactNode;
  styles?: React.CSSProperties | string;
  id?: any;
}

const ComponentBackground: React.FC<IComponentBackground> = ({ children, styles, id }) => {
  const { styleIsActive, activeGlobalState } = useContext(FabricJSContextNew);
  const isActive = id === styleIsActive;
  // || id === activeGlobalState?.fullJSON?.id;
  return (
    <div
      style={typeof styles === "object" ? styles : undefined} // Only pass `styles` if it's an object
      className={`${s.container}${isActive ? ` ${s.active}` : ""}${
        typeof styles === "string" ? ` ${styles}` : ""
      }`} // Append class if `styles` is a string
    >
      {children}
    </div>
  );
};

export default ComponentBackground;
