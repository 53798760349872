import React, { useContext, useState } from "react";
import s from "./CreateFolder.module.scss";
import ModalHeader from "../../ModalHeader";
import { useTranslation } from "react-i18next";
import Button from "../../../Button/Button";
import { usePostRequest } from "../../../../core/api/usePostRequest";
import { TextField } from "@mui/material";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import AppContext from "../../../../core/context/AppContext";
import { useApiRequest } from "../../../../core/api/useApiRequest";
const buttonStyles = {
  marginLeft: "auto",
  width: "200px",
};
interface CreateFolderProps {
  parent_id?: any;
  setCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
  postCreateFolderPath: string;
}

const CreateFolder = ({ parent_id, setCloseModal, postCreateFolderPath }: CreateFolderProps) => {
  const { t } = useTranslation();
  const { setForceFetchFolder } = useContext(AppContext);
  const notification = useNotification();
  const { sendRequest } = useApiRequest();

  // Initialize `input` and default `parent_id` to 0 if null or undefined
  const [input, setInput] = useState<{ name?: string; parent_id?: string | number }>({
    parent_id: parent_id ?? 0,
  });

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const createFolder = async () => {
    try {
      const response: any = await sendRequest(
        "post",
        `/creator/2d/${postCreateFolderPath}/categories`,
        input
      );

      setForceFetchFolder((prev) => !prev);
      setCloseModal((prev) => !prev);
      notification({
        id: uniqueID(),
        type: "SUCCESS",
        message: "SUCCESS",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("Add folder")} />
      <TextField name="name" label={t("Folder name")} onChange={(e) => inputHandler(e)} />
      <Button
        text={t("Save")}
        type="button"
        styles={buttonStyles}
        handleClick={() => createFolder()}
      />
    </div>
  );
};

export default CreateFolder;
