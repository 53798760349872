import React, { useState, useEffect, useContext } from "react";
import s from "./FilterComponent.module.scss";
import { useApiRequest } from "../../core/api/useApiRequest";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import LoadingState from "../LoadingState/LoadingState";
import AppContext from "../../core/context/AppContext";
import ProductComponent from "../ProductComponent/ProductComponent";

interface FilterComponentProps {
  endpoint: string;
  defaultSort?: string;
  defaultFilter?: string;
  defaultSearch?: string;
  defaultPagination?: number;
  category_id?: string; // Ensure category_id is typed as string (or optional)
  render: (data: any) => JSX.Element;
  isSearch?: boolean;
  isPagination: boolean;
  sortByDate?: boolean;
  forceFetch?: boolean;
  enableSordByDate?: boolean;
  onDelete?: (id: number) => void;
}

const loadingStyle = {
  minHeight: "300px",
};
const buttonStyle = {
  maxWidth: "150px",
};

const FilterComponent: React.FC<FilterComponentProps> = ({
  endpoint,
  defaultSort = "id",
  defaultFilter = "",
  defaultSearch = "",
  defaultPagination = 4,
  category_id = "",
  render,
  isSearch = false,
  isPagination,
  sortByDate,
  forceFetch,
  enableSordByDate,
  onDelete,
}) => {
  const [sort, setSort] = useState<string>(defaultSort);
  const [filter, setFilter] = useState<string>(defaultFilter);
  const [search, setSearch] = useState<string>(defaultSearch);
  const [pagination, setPagination] = useState<number>(defaultPagination);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [fetchedData, setFetchedData] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const { forceFetchReload } = useContext(AppContext);
  const { data, isLoading, error, sendRequest } = useApiRequest<any>();

  const fetchData = async () => {
    setIsDataLoading(true); // Set loading to true when fetching data

    // Build the query parameters
    let queryParams = `?sort=${sort}&paginate=${pagination}&page=${page}`;

    // Add filter if provided
    if (filter) {
      queryParams += `&filter=${filter}`;
    }

    // Add search if provided
    if (isSearch) {
      queryParams += `&s=${search}`;
    }

    // Conditionally add category_id to the query params only if provided
    if (category_id) {
      queryParams += `&filter=category_id:${category_id}`;
    }

    const response: any = await sendRequest("get", `${endpoint}${queryParams}`);

    if (response) {
      setFetchedData(response?.data || []); // Set the data
      setTotalPages(response?.meta?.last_page || 1); // Update the total number of pages from meta
    }
    setIsDataLoading(false); // Set loading to false after data is fetched
  };

  const handleDeleteProduct = (id: number) => {
    console.log("running delete ");
    setFetchedData((prevData) =>
      prevData.filter((product) => product.id !== id)
    );
  };

  useEffect(() => {
    fetchData();
  }, [
    sort,
    filter,
    search,
    pagination,
    page,
    forceFetch,
    category_id,
    forceFetchReload,
  ]); // Ensure category_id is included in the dependency array

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  // const dataToShow = fetchedData?.filter((el: any) => el.is_draft === false);
  // console.log(dataToShow, "fetchedData");
  useEffect(() => {
    setSearch(defaultSearch);
    setFilter(defaultFilter);
  }, [defaultFilter, defaultSearch]);
  return (
    <>
      {enableSordByDate ? (
        <button
          style={buttonStyle}
          onClick={() => setSort(sort === "id" ? "-id" : "id")}
        >
          Sort by ID {sort === "id" ? "Desc" : "Asc"}
        </button>
      ) : null}
      {error && <p>Error: {error.message}</p>}
      {isDataLoading ? (
        <LoadingState />
      ) : (
        <>
          {!isLoading &&
            !error &&
            render({ data: fetchedData, onDelete: handleDeleteProduct })}
        </>
      )}

      {isPagination && totalPages > 1 && (
        <div className={s.pagination}>
          <Stack spacing={1} sx={{ marginTop: "20px", flexGrow: 1 }}>
            <Pagination
              count={totalPages} // Set the total number of pages
              page={page} // Set the current page
              onChange={handlePageChange} // Handle page change
              variant="outlined"
              color="primary"
              siblingCount={1} // Number of sibling page links
              boundaryCount={0} // Number of boundary page links
            />
          </Stack>
        </div>
      )}
    </>
  );
};

export default FilterComponent;
