import React, { createContext, ReactNode, useState } from "react";
import { SearchOption } from "../../types/generalTypes";

interface TSearchContext {
  children: ReactNode;
}

interface SearchContextValue {
  isLoopDashboardOpen: boolean;
  setIsLoopDashboardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  searchType: SearchOption;
  setSearchType: React.Dispatch<React.SetStateAction<SearchOption>>;
}

const SearchContext = createContext<SearchContextValue>({
  isLoopDashboardOpen: false,
  setIsLoopDashboardOpen: () => {},
  searchInput: "",
  setSearchInput: () => {},
  searchType: "all",
  setSearchType: () => {},
});

export const SearchContextProvider = ({ children }: TSearchContext) => {
  const [isLoopDashboardOpen, setIsLoopDashboardOpen] =
    useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchType, setSearchType] = useState<SearchOption>("all");

  return (
    <SearchContext.Provider
      value={{
        isLoopDashboardOpen,
        setIsLoopDashboardOpen,
        searchInput,
        setSearchInput,
        searchType,
        setSearchType,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
