// 1 px = 0.1 mm = 0.00393701 in
const PX_TO_INCH = 0.0393701;

/**
 * originalPixelWidth, originalPixelHeight:
 *   The actual image resolution in real pixels (e.g., 150 px).
 *
 * scaledCanvasWidthPx, scaledCanvasHeightPx:
 *   How large the user scaled it on the canvas (in "canvas px").
 *   But now each "canvas px" = 0.1 mm = 0.00393701 in.
 */
export const getDPI = (
  originalPixelWidth: number,
  originalPixelHeight: number,
  scaledCanvasWidthPx: number,
  scaledCanvasHeightPx: number
) => {
  // Convert scaled width in "canvas px" to inches
  const scaledWidthInches = scaledCanvasWidthPx * PX_TO_INCH;
  const scaledHeightInches = scaledCanvasHeightPx * PX_TO_INCH;

  // DPI = real pixel count / physical size in inches
  const xDpi = originalPixelWidth / scaledWidthInches;
  const yDpi = originalPixelHeight / scaledHeightInches;

  return { xDpi, yDpi };
};

// 2) The main function to check DPI vs. your workingAreaDPI
export const calculateDIP = (object: any, workingArea: any, notification: any) => {
  // "Original" pixel data:
  // If Fabric's 'object.width' / 'object.height' changed by scaling,
  // you might need to store the real, original resolution somewhere else.
  const originalPixelWidth = object.originalWidth;
  const originalPixelHeight = object.originalHeight;

  // "Scaled" size on the canvas, each px=0.1mm
  const scaledCanvasWidthPx = object.originalWidth * object.scaleX; // or your scaled size
  const scaledCanvasHeightPx = object.originalHeight * object.scaleY; // or your scaled size

  // 3) Get the DPI based on the "1 px=1 mm" assumption

  const dpiResults = getDPI(
    originalPixelWidth,
    originalPixelHeight,
    scaledCanvasWidthPx,
    scaledCanvasHeightPx
  );

  console.log({
    dpiResults,
    scaledCanvasWidthPx,
    scaledCanvasHeightPx,
    workingArea,
    object,
  });
  console.log(dpiResults);

  // 4) Compare
  const isDPIWidthTooLow = dpiResults?.xDpi < workingArea?.dpi;
  const isDPIHeightTooLow = dpiResults?.yDpi < workingArea?.dpi;

  if (isDPIWidthTooLow || isDPIHeightTooLow) {
    const widthMessage = isDPIWidthTooLow
      ? `The horizontal DPI (${dpiResults.xDpi.toFixed(2)} DPI) is below the required ${
          workingArea?.dpi
        } DPI.`
      : "";
    const heightMessage = isDPIHeightTooLow
      ? `The vertical DPI (${dpiResults.yDpi.toFixed(2)} DPI) is below the required ${
          workingArea?.dpi
        } DPI.`
      : "";
    const message = [widthMessage, heightMessage].filter(Boolean).join(" ");

    notification({ id: new Date().toISOString(), message, type: "ERROR" });
  }
  // Add a flag indicating if the DPI is sufficient
  const isDpiSufficient = !isDPIWidthTooLow && !isDPIHeightTooLow;

  // Return al
  return { isDPIWidthTooLow, isDPIHeightTooLow, isDpiSufficient };
};
