import React, { ReactNode, useContext, useState } from "react";
import Breadcrumbs from "../components/Nav/Breadcrumbs/Breadcrumbs";
import s from "./Layout.module.scss";
import TopBar from "../components/Nav/TopBar/TopBar";
import MainNav from "../components/Nav/MainNav/MainNav";
import LeftBar from "../components/Nav/LeftBar/LeftBar";
import AppContext from "../core/context/AppContext";
import SearchContext from "../core/context/SearchContext";
import {
  DragDropContext,
  DropResult,
  OnBeforeCaptureResponder,
} from "react-beautiful-dnd";
import { useApiRequest } from "../core/api/useApiRequest";
import { useLocation } from "react-router-dom";
import {
  uniqueID,
  useNotification,
} from "../core/context/notifications/NotificationProvider";
import { useTranslation } from "react-i18next";
import SearchLoop from "../components/SearchLoop/SearchLoop";
interface ILayout {
  children: ReactNode;
}
const Layout = ({ children }: ILayout) => {
  const { folderPath, setFolderPath, setForceFetchFolder, setIsBeforeCapture } =
    useContext(AppContext);
  const { isLoopDashboardOpen, setIsLoopDashboardOpen } =
    useContext(SearchContext);
  const { sendRequest } = useApiRequest();
  const location = useLocation();
  const splitLocation = location.pathname.split("/")[1];

  const handleDragEnd = async (result: DropResult) => {
    setIsBeforeCapture(false); // Reset recalculation flag

    const { source, destination, draggableId, type } = result;
    console.log(destination, "destination");

    if (!destination) return;

    // If dropped in the same place, do nothing
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Determine the type of drag based on droppableId patterns or other metadata
    if (destination.droppableId.startsWith("folder-")) {
      await handleMoveToFolder(draggableId, destination.droppableId);
    } else if (destination.droppableId === "product-list") {
    }
    // Add more conditions if you have other droppable areas
  };
  const notification = useNotification();
  const { t } = useTranslation();
  const handeDragUpdate = (destination: any) => {
    console.log(destination.destination, "destination");
  };
  const handleMoveToFolder = async (
    draggableId: string,
    droppableId: string
  ) => {
    // Extract the folder ID from the droppable ID
    const folderId = droppableId.replace("folder-", "");

    // Extract item type and ID from draggableId
    const [itemType, itemId] = draggableId.split("-");

    // Map item type to API paths
    const apiPaths: Record<string, string> = {
      product: "templates",
      project: "projects",
    };

    // Determine the correct API path
    const apiPath = apiPaths[itemType];
    if (!apiPath) {
      console.error(`Invalid item type: ${itemType}`);
      return;
    }

    // Send the request to move the item
    const response = await sendRequest(
      "put",
      `creator/2d/${apiPath}/${itemId}`,
      {
        category_id: folderId,
      }
    );

    // Trigger folder refresh
    setForceFetchFolder((prevState) => !prevState);

    if (response) {
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: t(`${itemType} moved succesfully ${folderId}`),
      });
    }
  };

  // Handle recalculations before drag starts
  const onBeforeCapture = (beforeCapture: any) => {
    const draggableElement: any = document.querySelector(
      `[data-rbd-draggable-id="${beforeCapture.draggableId}"]`
    );
    if (draggableElement) {
      draggableElement.style.height = "25px"; // Adjust as needed
      draggableElement.style.width = "25px"; // Adjust as needed
    }
  };
  const isDashboard = location.pathname.startsWith("/dashboard");
  return (
    <div className={s.container}>
      <TopBar />
      <div className={s.children}>
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onDragEnd={handleDragEnd}
          onDragUpdate={handeDragUpdate}
        >
          <MainNav />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginLeft: "10px",
            }}
          >
            <div className={s.tools}>
              {isDashboard ? (
                <SearchLoop
                  setActive={setIsLoopDashboardOpen}
                  active={isLoopDashboardOpen}
                />
              ) : null}
              <Breadcrumbs
                isLoopOpen={isLoopDashboardOpen}
                styleOption={1}
                folderPath={folderPath}
                setFolderPath={setFolderPath}
              />
            </div>
            {children}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Layout;
