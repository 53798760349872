import React, { Dispatch, useContext } from "react";
import s from "./Search.module.scss";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../components/Chip/Chip";
import SearchContext from "../../../core/context/SearchContext";
import { SearchOption } from "../../../types/generalTypes";

interface ISearch {
  active: boolean;
  setActive: Dispatch<React.SetStateAction<boolean>>;
  options: SearchOption[];
  renderContent: (selectedOption: SearchOption) => React.ReactNode;
}

const Search = ({ active, setActive, options, renderContent }: ISearch) => {
  const { t } = useTranslation();
  const { searchType, setSearchType } = useContext(SearchContext);

  const handleOptionChange = (option: SearchOption) => {
    setSearchType(option);
  };

  return (
    <div className={s.container}>
      <div className={s.chips_container}>
        <span>
          {t("Search in")}{" "}
          {t(searchType.charAt(0).toUpperCase() + searchType.substring(1))}
        </span>
        {options.map((option) => (
          <ChipComponent
            key={option}
            label={t(option.charAt(0).toUpperCase() + option.slice(1))}
            isActive={searchType === option}
            handleClick={() => handleOptionChange(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default Search;
