import React, { CSSProperties } from "react";
import AddIconButton from "../../../../../components/AddIconButton/AddIconButton";
import { ReactComponent as ALetterIcon } from "../../../../../assets/svg/ALetterIcon.svg";
import { ReactComponent as PictureIcon } from "../../../../../assets/svg/PictureIcon.svg";

const style: CSSProperties = {
  position: "absolute",
  bottom: "0",
  left: "calc(50% - 150px)",
  transform: "translate(-50%, -50%)",
  display: "flex",
  gap: "10px",
};
const AddPanel = () => {
  return (
    <div style={style}>
      <AddIconButton handleClick={() => {}} tooltip="Add Text" Icon={ALetterIcon} />
      <AddIconButton handleClick={() => {}} tooltip="Add Text" Icon={PictureIcon} />
    </div>
  );
};

export default AddPanel;
