import React, { Dispatch, useContext, useState } from "react";
import s from "./SearchLoop.module.scss";
import { ReactComponent as LoopIcon } from "../../assets/icons/loop.svg";
import SearchContext from "../../core/context/SearchContext";
interface ISearchLoop {
  active: boolean;
  setActive: Dispatch<React.SetStateAction<boolean>>;
}
const SearchLoop = ({ active, setActive }: ISearchLoop) => {
  const [isFullWidth, setSsFullWidth] = useState<boolean>(false);

  const { searchInput, setSearchInput } = useContext(SearchContext);
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSearchInput(e.target.value);
  };
  return (
    <div className={`${s.container} ${isFullWidth ? s.active : ""}`}>
      <LoopIcon
        onClick={() => {
          setSsFullWidth(!isFullWidth);
          setActive(!active);
        }}
      />

      <input
        type="text"
        className={isFullWidth ? s.active : ""}
        onChange={(e) => handleInput(e)}
        value={searchInput}
      />
    </div>
  );
};

export default SearchLoop;
