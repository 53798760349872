import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./AddressForm.module.scss";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePostRequest } from "../../core/api/usePostRequest";
import Button from "../Button/Button";
import AuthContext from "../../core/context/AuthContext";
import { isEmptyString } from "../../helper/validation";
import {
  uniqueID,
  useNotification,
} from "../../core/context/notifications/NotificationProvider";

const buttonStyle = { marginLeft: "auto", width: "200px" };

export interface AddressInfo {
  billing_name: string;
  billing_street: string;
  billing_city: string;
  billing_postcode: string;
  billing_tin: string;
}

const defaultValue: AddressInfo = {
  billing_name: "",
  billing_street: "",
  billing_city: "",
  billing_postcode: "",
  billing_tin: "",
};

interface IAddressForm {
  setIsValid: (isValid: boolean) => void;
  setInput: Dispatch<SetStateAction<AddressInfo>>;
  input: AddressInfo;
}

const AddressForm = ({ setIsValid, setInput, input }: IAddressForm) => {
  const { t } = useTranslation();
  const { user, setForceFetch } = useContext(AuthContext);

  const [isNameValid, setIsNameValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [isTaxCodeValid, setIsTaxCodeValid] = useState(true);

  useEffect(() => {
    if (user && user.workspace) {
      const updatedValues: AddressInfo = {
        billing_name: user.workspace.billing_name || "",
        billing_street: user.workspace.billing_street || "",
        billing_city: user.workspace.billing_city || "",
        billing_postcode: user.workspace.billing_postcode || "",
        billing_tin: user.workspace.billing_tin || "",
      };
      setInput((prev) => ({ ...prev, ...updatedValues }));
    }
  }, [user]);

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleValidation();
    inputHandler(e);
  };

  const handleValidation = () => {
    setIsValid(
      !isEmptyString(input.billing_name) &&
        !isEmptyString(input.billing_street) &&
        !isEmptyString(input.billing_city) &&
        !isEmptyString(input.billing_postcode) &&
        !isEmptyString(input.billing_tin)
    );
  };

  const validateField = (
    value: string,
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsValid(!isEmptyString(value));
  };

  return (
    <div className={s.container}>
      <TextField
        onChange={handleChange}
        value={input.billing_name}
        name="billing_name"
        label={t("Name")}
        onBlur={() => validateField(input.billing_name, setIsNameValid)}
        error={!isNameValid}
      />
      <TextField
        onChange={handleChange}
        value={input.billing_street}
        name="billing_street"
        label={t("Address")}
        onBlur={() => validateField(input.billing_street, setIsAddressValid)}
        error={!isAddressValid}
      />
      <div className={s.city_input}>
        <TextField
          onChange={handleChange}
          value={input.billing_postcode}
          name="billing_postcode"
          label={t("Zip code")}
          onBlur={() =>
            validateField(input.billing_postcode, setIsZipCodeValid)
          }
          error={!isZipCodeValid}
        />
        <TextField
          onChange={handleChange}
          value={input.billing_city}
          name="billing_city"
          label={t("City")}
          onBlur={() => validateField(input.billing_city, setIsCityValid)}
          error={!isCityValid}
        />
      </div>
      <TextField
        onChange={handleChange}
        value={input.billing_tin}
        name="billing_tin"
        label={t("Tax number")}
        onBlur={() => validateField(input.billing_tin, setIsTaxCodeValid)}
        error={!isTaxCodeValid}
      />
    </div>
  );
};

export default AddressForm;
