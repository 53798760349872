import { useTranslation } from "react-i18next";
import s from "./CreateModal.module.scss";
import ImportFile from "./ImportFile/ImportFile";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../core/context/AuthContext";

interface CreateModalProps {
  type: "project" | "product";
  children: React.ReactNode;
  setFileToSend: (file: any) => void;
  onFileSelectedAndInvoke?: (file: any) => void;
}

type TranslationType = "CreateProject" | "CreateProduct" | "CreateProjectFree";

interface ModalType {
  translation: TranslationType;
}

const CreateModal = ({
  children,
  type,
  setFileToSend,
  onFileSelectedAndInvoke,
}: CreateModalProps) => {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const typeObject = useRef<ModalType>({ translation: "CreateProduct" });

  useEffect(() => {
    let translation: TranslationType =
      type === "product"
        ? "CreateProduct"
        : user.subscription.plan.level < 1 // TODO: Confirm subscription level for business and higher
        ? "CreateProjectFree"
        : "CreateProject";
    typeObject.current.translation = translation;
  }, [type, user]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h2>{t(typeObject.current.translation + ".one")}</h2>
        <h4>{t(typeObject.current.translation + ".two")}</h4>
      </div>
      <div className={s.content}>
        <div className={s.left}>{children}</div>
        <div className={s.right}>
          <ImportFile
            setFileToSend={setFileToSend}
            onFileSelectedAndInvoke={onFileSelectedAndInvoke}
            // onFileSelectedAndInvoke={!user ? handleNotLoggedInFlow : undefined}  Only invoke automatically if not logged in
          />
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
