export const toJSONCustome = (canvas: any) => {
  const canvasJSON = canvas.toJSON([
    "id",
    "name",
    "type",
    "opacity",
    "level",
    "selectable",
    "lockMovementX",
    "lockMovementY",
    "lockScalingX",
    "lockScalingY",
    "lockRotation",
    "hasControls",
    "hasBorders",
    "padding",
    "transparentCorners",
    "borderColor",
    "cornerColor",
    "cornerStyle",
    "cornerSize",
    "lockScalingFlip",
    "uuid",
    "guid",
    "isClone",
    "order",
    "dpi",
    "originalWidth",
    "originalHeight",
    "dpiWidth",
    "dpiHeight",
    "isDpiSufficient",
    
  ]);

  return canvasJSON.objects;
};
