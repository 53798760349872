import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import s from "./FolderTreeStructure.module.scss";
import { IFolder, IFolderTreeItem } from "../../../core/types/types";
// ^ Make sure to import your custom IFolderTreeItem type

export interface IFolderTreeStructureComponent {
  requestPath: string;
  setSelectedFolder?: (folder: any) => void;
  selectedFolder?: any;
}

const FolderTreeStructure = ({ requestPath, setSelectedFolder }: IFolderTreeStructureComponent) => {
  const { sendRequest } = useApiRequest();
  const { t } = useTranslation();

  /** Our tree data array, typed with IFolderTreeItem */
  const [treeData, setTreeData] = useState<IFolderTreeItem[]>([]);

  const [selectedNodeId, setSelectedNodeId] = useState<any>(null);
  const [selectedNodeName, setSelectedNodeName] = useState<string>("");
  const [selectedNode, setSelectedNode] = useState<IFolderTreeItem | null>(null);

  const [openTree, setOpenTree] = useState<boolean>(false);
  const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Fetch folder data from your API and build into tree structure */
  const fetchFolders = async () => {
    try {
      const response: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const data: IFolder[] = response.data;

      const treeItems = buildTreeData(data);

      // Example: find the node with a "root" name:
      const rootNode = treeItems.find((node) => node?.name?.toLowerCase() === "root");

      if (rootNode) {
        setSelectedNode(rootNode);
        setSelectedNodeId(rootNode.id);
        setSelectedNodeName(rootNode.name);
        if (setSelectedFolder) setSelectedFolder(rootNode);
      }

      setTreeData(treeItems);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  /**
   * Convert your IFolder[] into a tree of IFolderTreeItem.
   * Put your custom fields (name, parent, etc.) inside 'data'.
   */
  const buildTreeData = (items: IFolder[]): IFolderTreeItem[] => {
    const idToNodeMap: Record<number, IFolderTreeItem> = {};

    // 1) Create a map of ID -> node
    items.forEach((item) => {
      idToNodeMap[item.id] = {
        id: item.id.toString(),

        name: item.name,
        parent: null,

        children: [],
      };
    });

    const roots: IFolderTreeItem[] = [];

    // 2) Assign children to parents
    items.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parent_id === 0) {
        // Root node
        roots.push(node);
      } else {
        const parent = idToNodeMap[item.parent_id];
        if (parent) {
          parent.children!.push(node);
          node.parent = parent;
        } else {
          console.warn(`Parent with ID ${item.parent_id} not found for item ${item.id}`);
        }
      }
    });

    return roots;
  };

  /** Tells MUI TreeView how to identify each item uniquely */
  const getItemId = (item: IFolderTreeItem) => {
    return item.id;
  };

  /**
   * Called when user selects a node from the tree
   * If itemId is null => nothing is selected
   */
  const handleSelectedItemsChange = (event: React.SyntheticEvent, itemId: string | null) => {
    setSelectedNodeId(itemId);

    if (itemId == null) {
      setSelectedNode(null);
      setSelectedNodeName("");
      if (setSelectedFolder) setSelectedFolder(0);
    } else {
      const node = findNodeById(treeData, itemId);
      setSelectedNode(node);
      if (setSelectedFolder) setSelectedFolder(node);

      if (node) {
        // build out path from its ancestors
        let path = node.name;
        let parent = node.parent;
        while (parent) {
          path = parent.name + `/${path}`;
          parent = parent.parent;
        }
        setSelectedNodeName(path || "");
      }
    }
  };

  /** Recursively find a node by ID in the tree */
  const findNodeById = (nodes: IFolderTreeItem[], targetId: string): IFolderTreeItem | null => {
    for (const node of nodes) {
      if (node.id === targetId) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const found = findNodeById(node.children as IFolderTreeItem[], targetId);
        if (found) return found;
      }
    }
    return null;
  };

  /** Expand logic so that selected node's ancestors remain expanded */
  const handleSelectedFolderClick = () => {
    if (openTree && selectedNode) {
      const expandedIds = [selectedNode.id];
      let parent = selectedNode.parent;
      while (parent) {
        expandedIds.push(parent.id);
        parent = parent.parent;
      }
      setExpandedNodeIds(expandedIds);
    }
    setOpenTree(!openTree);
  };

  /** Fired when user toggles (expands/collapses) an item */
  const handleItemExpansionToggle = (
    event: React.SyntheticEvent,
    id: string,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      setExpandedNodeIds((prev) => [...prev, id]);
    } else {
      setExpandedNodeIds((prev) => prev.filter((nodeId) => nodeId !== id));
    }
  };

  /** Close tree if user clicks outside */
  const handleOutsideClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setOpenTree(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={s.container} ref={containerRef}>
      <div className={s.folder_top}></div>

      <TextField
        className={s.input}
        size="small"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            color: "#598dd5",
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: "#598dd5",
          },
        }}
        value={selectedNodeName}
        fullWidth
        margin="normal"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleSelectedFolderClick} edge="end" centerRipple={true}>
                {openTree ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <div className={`container-folder-tree-structure ${s.folder_tree_container}`}>
        {openTree && (
          <RichTreeView
            className={s.folder_tree}
            items={treeData}
            selectedItems={selectedNodeId}
            expandedItems={expandedNodeIds}
            onSelectedItemsChange={handleSelectedItemsChange}
            multiSelect={false}
            getItemId={getItemId}
            onItemExpansionToggle={handleItemExpansionToggle}
            /** Show the 'name' from data as the label */
            getItemLabel={(item) => item.name || "Unnamed Item"}
          />
        )}
      </div>
    </div>
  );
};

export default FolderTreeStructure;
