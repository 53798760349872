import React, { useState } from "react";
import Create from "../../../components/Create/Create";
import createProject from "../../../assets/images/new-project.png";
import createProduct from "../../../assets/images/new-product.png";
import s from "./Add.module.scss";
import SlideUpModal from "../../../components/Modals/Modal/SildeUpModal";
import CreateProject from "../../../components/Modals/CreateModals/CreateProject/CreateProject";
import { useTranslation } from "react-i18next";
import CreateProduct from "../../../components/Modals/CreateModals/CreateProduct/CreateProduct";
const Add = () => {
  const { t } = useTranslation();
  const [openProjectCreate, setOpenProjectCreate] = useState<boolean>(false);
  const [openProductCreate, setOpenProductCreate] = useState<boolean>(false);

  return (
    <>
      <div className={s.add}>
        <Create
          title={t("New Product")}
          image={createProduct}
          handleClick={() => setOpenProductCreate(true)}
        />
        <Create
          title={t("New Project")}
          image={createProject}
          handleClick={() => setOpenProjectCreate(true)}
        />
      </div>
      <SlideUpModal
        open={openProjectCreate}
        onClose={() => setOpenProjectCreate(false)}
      >
        <CreateProject />
      </SlideUpModal>
      <SlideUpModal
        open={openProductCreate}
        onClose={() => setOpenProductCreate(false)}
      >
        <CreateProduct setCloseModal={setOpenProductCreate} />
      </SlideUpModal>
    </>
  );
};

export default Add;
