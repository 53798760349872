import React, { useContext, useState } from "react";
import Domain from "./Domain/Domain";
import MiniNav from "../../components/Nav/MiniNav/MiniNav";
import s from "./Settings.module.scss";
import Personalization from "./Personalization/Personalization";
import WorkSpace from "./WorkSpace/WorkSpace";
import Profile from "./Profile/Profile";
import RenderActionComponent from "../../components/RenderActionComponent/RenderActionComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../../core/context/AuthContext";
import { TNavigationItem } from "../../core/types/types";

const actions: Record<string, JSX.Element> = {
  profile: <Profile />,
  domain: <Domain />,
  personalization: <Personalization />,
  workspace: <WorkSpace />,
};

const Settings = () => {
  const { t } = useTranslation();
  const navigationMenu: TNavigationItem[] = [
    {
      name: t("Account"),
      link: "profile",
      ava: "profile",
      premium: false,
    },
    {
      name: t("Work area"),
      link: "workspace",
      ava: "work_space",
      premium: false,
    },
    {
      name: t("Personalisation"),
      link: "personalization",
      ava: "personalization",
      premium: false,
    },
    {
      name: t("Domain"),
      link: "domain",
      ava: "domain",
      premium: true,
    },
  ];
  const [actionState, setActionState] = useState<any>(navigationMenu[0]);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const setAction = (newAction: any) => {
    if (user?.subscription?.plan?.level === 0 && newAction.premium) {
      return;
    }
    console.log(newAction, "newAction");
    setActionState(newAction);
    navigate(`/settings/${newAction.link}`);
  };

  return (
    <div className={s.container}>
      <MiniNav navigation={navigationMenu} action={actionState} setAction={setAction} />
      <RenderActionComponent actions={actions} action={actionState.link} />
    </div>
  );
};

export default Settings;
