import React, { useContext } from "react";
import s from "./GraphicList.module.scss";

import ProductComponent from "../../../components/ProductComponent/ProductComponent";
import DashboardTopHeader from "../../../components/DashboardTopHeader/DashboardTopHeader";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useTranslation } from "react-i18next";
import FilterComponent from "../../../components/FilterComponent/FilterComponent";
import GraphicComponent from "../../../components/GraphicComponent/GraphicComponent";
const GraphicList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveGlobalState } = useContext(FabricJSContextNew);

  //   const productHandler = (selectedView: any, fullJSON: any) => {
  //     setActiveGlobalState({ canvasType: "templates", fullJSON: fullJSON });
  //     navigate(`/design-studio/product/${fullJSON.token}`);
  //   };

  return (
    <div className={s.container}>
      <DashboardTopHeader header={t("Graphics")} navigateTo={"/media"} />
      <div className={s.list_container}>
        <FilterComponent
          endpoint="/media"
          defaultSort="id"
          defaultPagination={5}
          isPagination={false}
          render={({ data, onDelete }) => (
            <div className={s.map_container}>
              {data?.map((el: any, i: number) => (
                <GraphicComponent
                  key={`dashboard-graphic-last-map${i}`}
                  variant="medium"
                  id={`dashboard-graphic-last-map${i}`}
                  imgSrc={el?.url}
                  handleClick={() => {}}
                  onDelete={onDelete}
                  graphic={el}
                  componentOptions="1"
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default GraphicList;
