import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Text from "./TextLeftBar/Text";
import s from "./RenderAction.module.scss";

import GraphicsLeftBar from "./GraphicsLeftBar/GraphicsLeftBar";
import ProductsLeftBar from "./ProductsLeftBar/ProductsLeftBar";
import ProjectsLeftBar from "./ProjectsLeftBar/ProjectsLeftBar";

interface RenderActionsProps {
  action: string;
  slideDirection: string;
  activeItem: null | string;
  open: boolean;
  setActiveItem: (arg: string) => void;
  setOpen: (arg: boolean) => void;
}
const RenderAction = ({ action, open }: RenderActionsProps) => {
  const actions: Record<string, JSX.Element> = {
    graphics: <GraphicsLeftBar />,
    text: <Text />,
    products: <ProductsLeftBar />,
    projects: <ProjectsLeftBar />,
  };

  const variants = {
    enter: {
      x: -270, // Slide in from the left
      opacity: 0,
    },
    center: {
      x: 0, // Center (visible)
      opacity: 1,
    },
    exit: {
      x: -270, // Slide out to the left
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      {open && action && (
        <motion.div
          className={s.sliding_container}
          key={action}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.25, ease: "easeInOut" }}
          style={{
            zIndex: 1,
            marginLeft: "64px", // Adjust to avoid overlapping the icons
          }}
        >
          {actions[action] || null}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RenderAction;
