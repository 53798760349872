import React, { useContext, useEffect, useState } from "react";
import s from "./OnboardPaymentPlan.module.scss";
import PaymentPlan from "../../../components/PaymentPlan/PaymentPlan";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { dummyPaymentPlans } from "../../../dummy-data/paymentPlans";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { Link, useNavigate } from "react-router-dom";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import AuthContext from "../../../core/context/AuthContext";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { IPlan } from "../../../components/Nav/MainNav/type";

type PaymentType = "monthly" | "yearly";

const paymentStyles = {
  margin: "auto",
  borderRadius: "32px",
};

//TODO: handle yearly, monthly values in PaymentPlan
const OnboardPaymentPlan = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setForceFetch, setRegistrationSteps } = useContext(AuthContext);
  const [type, setType] = useState<PaymentType>("monthly");
  const [plans, setPlans] = useState<[]>([]);
  const [activePlan, setActivePlan] = useState<{
    index: number;
    name: string;
    level: number;
  }>({
    index: -1,
    name: "",
    level: -1,
  });

  const { sendRequest, isLoading } = useApiRequest();
  const handlePlanClick = (index: number, name: string, level: number) => {
    setActivePlan({
      index,
      name,
      level,
    });
  };

  // const getButtonStyles = (index: number) => {
  //   const styles = {
  //     background: index === activePlan.index ? "gray" : "",
  //     color: index === activePlan.index ? "white" : "",
  //     width: index === activePlan.index ? "220px" : "220px",

  //     margin: "auto",
  //   };

  //   const disabled = index === activePlan.index;

  //   return {
  //     styles,
  //     disabled,
  //   };
  // };
  const fetchPaymentPlan = async () => {
    const { data }: any = await sendRequest("get", "/plans");
    const selected = data.find((plan: any) => plan.level === 1);
    if (selected)
      setActivePlan({
        index: selected.id,
        name: selected.name,
        level: selected.level,
      });
    else {
      setActivePlan({
        index: data[0].id,
        name: data[0].name,
        level: data[0].level,
      });
    }
    setPlans(data);
  };
  useEffect(() => {
    fetchPaymentPlan();
  }, []);

  const handleNextStep = async () => {
    const response = await sendRequest("post", "account/plan", {
      plan_id: activePlan.index,
    });

    console.log(response);
    if (response) {
      setRegistrationSteps((prev: any) => ({
        ...prev,
        plan_id: activePlan.index,
        plan_level: activePlan.level,
      }));

      //TODO: Handle Enterprise level
      const path = activePlan.level === 0 ? "/onboard/profile-setup" : "/onboard/billing-info";
      setForceFetch((prev: boolean) => !prev);
      navigate(path);
      console.log("Working");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <div className={s.top}>
            <h2>{t("PlanSelectionComponent.Selected subscription")}</h2>
            <div className={s.switcher_box}>
              <div
                className={`${s.monthly} ${type === "monthly" ? s.active : ""}`}
                onClick={() => setType("monthly")}
              >
                <span>{t("PlanSelectionComponent.Monthly")}</span>
                <div>
                  <span>30% </span>
                  <span>{t("PlanSelectionComponent.Cheaper")}</span>
                </div>
              </div>
              <div
                className={`${s.yearly} ${type === "yearly" ? s.active : ""}`}
                onClick={() => setType("yearly")}
              >
                <span>{t("PlanSelectionComponent.Yearly")}</span>
                <div>
                  <span>50% </span>
                  <span>{t("PlanSelectionComponent.Cheaper")}</span>
                </div>
              </div>
            </div>
          </div>
          <section className={s.plans_container}>
            {plans.map((el: any, id) => (
              <PaymentPlan
                key={id}
                backgroundStyles={paymentStyles}
                el={el}
                active={activePlan.index === el.id}
                handleClick={() => handlePlanClick(el.id, el.name, el.level)}
                selectedType={type}
              />
            ))}
          </section>
          {/* <Swiper
            navigation={true}
            modules={[Navigation]}
            className={s.subscription_swiper}
          >
            {plans.map((el: any, i: number) => (
              <SwiperSlide
                key={`payment plant map registration ${i}`}
                className={s.swiper_slide}
              >
                <PaymentPlan
                  backgroundStyles={paymentStyles}
                  el={el}
                  handleClick={() => handleButtonClick(i)}
                />
              </SwiperSlide>
            ))}
          </Swiper> */}
          <div className={s.bottom}>
            <Link to="/register">&#8592; {t("Go back")}</Link>
            <Button
              text={
                isLoading
                  ? t("Loading")
                  : t("PlanSelectionComponent.Continue with") + " " + activePlan.name
              }
              type="button"
              handleClick={() => handleNextStep()}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardPaymentPlan;
