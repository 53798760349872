import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./TopToolsGraphics.module.scss";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { MenuItem, Select, Slider, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import changeImg from "../../../../../fabric-functions/ChangeImage";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import { hsvaToHex, Sketch } from "@uiw/react-color";

const filterConfig: any = {
  saturation: { min: 0, max: 1, step: 0.01, default: 0 },
  brightness: { min: -1, max: 1, step: 0.01, default: 0 }, // Adjust range for proper brightness control
  contrast: { min: -1, max: 1, step: 0.01, default: 0 }, // Contrast uses -1 to 1
  blur: { min: 0, max: 1, step: 0.01, default: 0 },
  changeColor: { default: { color: "#FF0000", alpha: 0.5, mode: "tint" } }, // Example for complex filter

  // Fabric.js supports 0 to 1 for blur
  // sepia: { min: 0, max: 1, step: 0.1, default: 0 }, // Sepia is a toggle filter with a range 0 to 1
  // grayscale: { min: 0, max: 1, step: 0.1, default: 0 }, // Grayscale ranges from 0 to 1
  // invert: { min: 0, max: 1, step: 0.1, default: 0 }, // Invert is a toggle filter with 0 to 1
  // pixelate: { min: 1, max: 50, step: 1, default: 4 }, // Pixelate block size can go up to 50
  // removeColor: { min: 0, max: 1, step: 0.1, default: 0 }, // RemoveColor works with 0 to 1 tolerance
  // emboss: { min: 0, max: 1, step: 0.1, default: 0 },
  // hueRotation: { min: 0, max: 1, step: 0.1, default: 0 },

  // tint: { min: 0, max: 1, step: 0.1, default: 0 },
};

const TopToolsGraphics = () => {
  const { t } = useTranslation();
  const [colorFromPallet, setColorFromPallet] = useState<any>(null); // Red

  const { canvasContext, canvasActiveObject, setCanvasActiveObject } =
    useContext(FabricJSContextNew);
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState(filterConfig[filter]?.default || 0);
  const [isFiltersMenu, setIsFiltersMenu] = useState<boolean>(false);
  const [alpha, setAlpha] = useState<any>(0);
  const sketchRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (filter && canvasActiveObject?.objectJSON) {
      const existingValue = getFilterValue(canvasActiveObject.objectJSON.filters, filter);
      if (existingValue !== filterValue) {
        setFilterValue(existingValue ?? (filterConfig[filter]?.default || 0));
      }
    }
  }, [canvasActiveObject, filter, filterValue]);

  useEffect(() => {
    if (canvasActiveObject?.objectJSON?.filters) {
      // Find the alpha filter from the filters array
      const alphaFilter = canvasActiveObject?.objectJSON?.filters?.find(
        (filter: any) => filter.type === "alpha" // Ensure this matches your filter type
      );

      // Set the alpha value if the filter exists
      if (alphaFilter) {
        setAlpha(alphaFilter.alpha ?? 0); // Fallback to 0 if undefined
      }
    }
  }, [canvasActiveObject]);

  const getFilterValue = (filters: any, filterType: any) => {
    const formattedFilter = filterType.charAt(0).toUpperCase() + filterType.slice(1);

    const filter = filters?.find((f: any) => f.type === formattedFilter);
    if (filter) {
      return filter[filterType.toLowerCase()] ?? null;
    }
    return null;
  };

  const handleFilterChange = (e: any) => {
    e.stopPropagation();
    const selectedFilter = e.target.value as string;
    setFilter(selectedFilter);
    setIsFiltersMenu(true);

    if (canvasActiveObject?.objectJSON && selectedFilter) {
      const currentFilters = canvasActiveObject.objectJSON.filters || [];
      const existingValue = getFilterValue(currentFilters, selectedFilter);
      const defaultValue = filterConfig[selectedFilter]?.default || 0;
      const filterValueToApply = existingValue ?? defaultValue;

      setFilterValue(filterValueToApply);

      // Apply the filter value to the canvas immediately
      changeImg(canvasContext, { [selectedFilter]: filterValueToApply }, setCanvasActiveObject);
    }
  };

  const changeImgColor = (color: any) => {
    const selectedFilter = "changeColor";

    const hex = hsvaToHex(color);
    const filterValueToApply = {
      mode: "tint",
      color: hex,
      alpha: color.a,
    };

    // Apply the filter value to the canvas immediately
    changeImg(canvasContext, { [selectedFilter]: filterValueToApply }, setCanvasActiveObject);
  };

  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;

    setFilterValue(value); // Update the state

    if (filter) {
      // Pass the updated value to the canvas
      changeImg(canvasContext, { [filter]: value }, setCanvasActiveObject);
    }
  };

  const flipXAxisHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      changeImg(canvasContext, { flipX: !activeObject.flipX }, setCanvasActiveObject);
    }
  };

  const flipYAxisHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      changeImg(canvasContext, { flipY: !activeObject.flipY }, setCanvasActiveObject);
    }
  };

  const [showColorPallete, setShowCollorPallete] = useState<boolean>(false);

  const colorHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    console.log("click");
    setShowCollorPallete(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sketchRef.current && !sketchRef.current.contains(event.target as Node)) {
      setColorFromPallet(false); // Close the state if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const palletColor = canvasActiveObject?.objectJSON?.filters?.find(
      (el: any) => el.mode === "tint"
    );

    setColorFromPallet(palletColor?.color || "#FF0000");
  }, [canvasActiveObject]);
  useEffect(() => {}, []);

  return (
    <div className={s.container}>
      <div className={s.color_pallet_container} onClick={colorHandler}>
        <div
          className={s.current_color}
          style={{
            background: colorFromPallet || "#000000",
          }}
        >
          <div>
            {showColorPallete && (
              <div className={s.pallet} ref={sketchRef}>
                <Sketch
                  className={s.pallet}
                  style={{ marginLeft: 20 }}
                  color={colorFromPallet}
                  onChange={(color) => {
                    setColorFromPallet(hsvaToHex(color.hsva));
                    changeImgColor(color.hsva);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipH")} placement="bottom">
          <VerticalAlignCenterIcon
            className={s.horizontal}
            onClick={flipXAxisHandler}
            fontSize="small"
          />
        </Tooltip>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipV")} placement="bottom">
          <VerticalAlignCenterIcon onClick={flipYAxisHandler} fontSize="small" />
        </Tooltip>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipX")} placement="bottom">
          <AlignVerticalCenterIcon className={s.horizontal} fontSize="small" />
        </Tooltip>
      </div>
      <div className={s.icon_container}>
        <Tooltip title={t("GraphicsToolBar.flipY")} placement="bottom">
          <AlignVerticalCenterIcon fontSize="small" />
        </Tooltip>
      </div>
      <div className={s.filter_container}>
        <Select
          sx={{ height: 38 }}
          value={filter}
          onChange={(e) => handleFilterChange(e)}
          displayEmpty
          inputProps={{ "aria-label": "Filter Select" }}
        >
          <MenuItem value="">
            <em>Select Filter</em>
          </MenuItem>
          {Object.keys(filterConfig).map((filterKey) => (
            <MenuItem key={filterKey} value={filterKey}>
              {filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </div>
      {isFiltersMenu && (
        <div className={s.slider_container}>
          <Slider
            value={filterValue}
            onChange={handleChangeSlider}
            min={filterConfig[filter]?.min}
            max={filterConfig[filter]?.max}
            step={filterConfig[filter]?.step}
            aria-labelledby="filter-slider"
            valueLabelDisplay="auto"
          />
        </div>
      )}
    </div>
  );
};

export default TopToolsGraphics;
