import { width } from "@mui/system";
import { fabric } from "fabric";

const updateCanvasObjectsList = (
  canvas: fabric.Canvas,
  setCanvasObjectList: (objects: any) => void
): void => {
  if (canvas) {
    const allObjects = canvas.getObjects();

    // Filter objects based on specific criteria
    const objects = allObjects.filter((obj: any) => {
      const id = typeof obj.id === "string" ? obj.id : "";
      const isClone = id.endsWith("-clone");
      const name = typeof obj?.name === "string" ? obj?.name : "";

      return (
        ((id.includes("-img") || id.includes("-text")) && !isClone) ||
        name.includes("workingArea") ||
        obj?.level === 3 // Include level 3 objects
      );
    });
    const canvasObjectListMap = objects.map((obj: any) => {
      return {
        name: obj?.name,
        type: obj?.type,
        level: obj?.level,
        order: obj?.order,
        // originalHeight: obj?.originalHeight,
        // originalWidth: obj?.originalHeight,
        // width: obj?.originalWidth * obj?.scaleX,
        // height: obj?.originalHeight * obj?.scaleY,
        // Add any other keys you want to include
      };
      // return obj;
    });
    // console.log(canvasObjectListMap, "canvasObjectListMap");
    canvas.renderAll();
    setCanvasObjectList(objects);
  }
};

const reorderCanvasObjects = (canvas: fabric.Canvas, objects: any): void => {
  if (!canvas) return;

  const activeObject = canvas.getActiveObject();
  canvas.discardActiveObject();

  const levels = [1, 2, 3, 4, 5, 6];
  const objectsByLevel: { [key: number]: any[] } = {};
  levels.forEach((level) => {
    objectsByLevel[level] = objects.filter((obj: any) => obj.level === level);
  });

  // Sort objects within levels
  levels.forEach((level) => {
    objectsByLevel[level].sort((a: any, b: any) => a.order - b.order);
  });

  // Combine all objects in order
  const allObjectsInOrder = levels.map((level) => objectsByLevel[level]).flat();

  // Do not overwrite the 'order' property here
  // If needed, you can assign a global order or simply rely on the existing order

  canvas.clear();

  allObjectsInOrder.forEach((obj: any) => {
    canvas.add(obj);
    obj.dirty = true;
  });
  const canvasObjectListMap = allObjectsInOrder.map((obj: any) => {
    return {
      name: obj.name,
      type: obj.type,
      level: obj.level,
      order: obj.order,
      // Add any other keys you want to include
    };
  });
  // console.log(canvasObjectListMap, "canvas reorder map");
  if (activeObject) {
    canvas.setActiveObject(activeObject);
  }
  canvas.renderAll();
};

export { updateCanvasObjectsList, reorderCanvasObjects };
