import React from "react";
import s from "./GoogleAuthButton.module.scss";

interface IButtonProps {
  handleClick?: () => void;
  text?: string;
  styles?: React.CSSProperties;
  name?: string;
}

const GoogleAuthButton: React.FC<IButtonProps> = ({
  handleClick,
  text = "Google",
  styles = {},
  name,
}) => {
  return (
    <div className={s.container}>
      <div className={s.button} style={styles} onClick={handleClick}>
        <img src="/icons/google.svg" alt="google" />
        {text}
      </div>
    </div>
  );
};

export default GoogleAuthButton;
