import React, { useContext, useState } from "react";
import s from "./DeleteFolder.module.scss";
import ModalHeader from "../../ModalHeader";
import { useTranslation } from "react-i18next";
import Button from "../../../Button/Button";
import { usePostRequest } from "../../../../core/api/usePostRequest";
import { TextField } from "@mui/material";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import AppContext from "../../../../core/context/AppContext";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import { IFolder } from "../../../../core/types/types";
const buttonStyles = {
  width: "200px",
  background: "red",
  color: "white",
};
interface IDeleteFolder {
  parent_id?: string;
  setCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
  path: string;
  folder: IFolder;
}

const DeleteFolder = ({ parent_id, setCloseModal, path, folder }: IDeleteFolder) => {
  const { t } = useTranslation();
  const { setForceFetchFolder, currentFolder } = useContext(AppContext);
  const { sendRequest, data } = useApiRequest();
  const notification = useNotification();

  const deleteFolder = async () => {
    try {
      const response = await sendRequest("delete", `/creator/2d/${path}/categories/${folder?.id}`);
      if (response) {
        setForceFetchFolder((prev) => !prev);
        setCloseModal((prev) => !prev);
        notification({
          id: uniqueID(),
          type: "SUCCESS",
          message: "SUCCESS",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("DeleteFolderModal.Are you sure")} />
      <p>{t("DeleteFolderModal.one")}</p>
      <div className={s.button_div}>
        <Button
          text={t("Go back")}
          type="button"
          handleClick={() => setCloseModal((prev) => !prev)}
        />
        <Button
          text={t("DeleteFolderModal.button")}
          type="button"
          styles={buttonStyles}
          handleClick={() => deleteFolder()}
        />
      </div>
    </div>
  );
};

export default DeleteFolder;
