import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Payments from "../../Subscribtions/Payments/Payments";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import s from "./OnboardPayment.module.scss";
import { useTranslation } from "react-i18next";

const OnboardPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePayment = () => {
    navigate("/onboard/profile-setup");
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.headers}>
          <h2>VIDELINK</h2>
          <h3>Business</h3>
        </div>
        <Payments />
        <Button
          text={t("Next")}
          type="button"
          handleClick={handlePayment}
          //disabled={!isFormValid}
          //isLoading={isLoading}
        />
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardPayment;
