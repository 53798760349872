import React from "react";
import s from "./MessageComponent.module.scss";

export interface MessageProps {
  type: "error" | "info";
  text: string;
}

const MessageComponent: React.FC<MessageProps> = ({ type, text }) => {
  return (
    <div className={`${s.container} ${s[type]}`}>
      <img className={s.icon} src={`/icons/${type}.svg`} alt="message" />
      <span className={s.text}>{text}</span>
    </div>
  );
};

export default MessageComponent;
