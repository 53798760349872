import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion"; // Import Framer Motion
import DCWrapper from "../../components/DashboardContainerWrapper/DCWrapper";
import s from "./Dashboard.module.scss";
import Add from "./Add/Add";
import ProductList from "./ProductList/ProductList";
import ProjectList from "./ProjectList/ProjectList";
import GraphicList from "./GraphicsList/GraphicList";
import Search from "./Search/Search";
import AppContext from "../../core/context/AppContext";
import RenderActionComponent from "../../components/RenderActionComponent/RenderActionComponent";
import AllSearchList from "./Search/components/AllSearchList.tsx/AllSearchList";
import SearchContext from "../../core/context/SearchContext";
import ProductSearchList from "./Search/components/ProductSearchList/ProductSearchList";
import ProjectSearchList from "./Search/components/ProjectSearchList/ProjectSearchList";
import GraphicSearchList from "./Search/components/GraphicSearchList/GraphicSearchList";

const actions = {
  all: <AllSearchList />,
  products: <ProductSearchList />,
  projects: <ProjectSearchList />,
  graphics: <GraphicSearchList />,
};

const Dashboard = () => {
  const { isLoopDashboardOpen, setIsLoopDashboardOpen, searchType } =
    useContext(SearchContext);

  useEffect(() => {
    return () => {
      setIsLoopDashboardOpen(false);
    };
  }, [setIsLoopDashboardOpen]);

  return (
    <DCWrapper>
      <AnimatePresence>
        {isLoopDashboardOpen ? (
          <motion.div
            key="search"
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3, delay: 0.31 },
            }}
            exit={{ opacity: 0, y: 20, transition: { duration: 0.3 } }}
          >
            <Search
              active={isLoopDashboardOpen}
              setActive={setIsLoopDashboardOpen}
              options={["all", "products", "projects", "graphics"]}
              renderContent={() => null} // No specific rendering in Search
            />
            <RenderActionComponent action={searchType} actions={actions} />
          </motion.div>
        ) : (
          <motion.div
            key="dashboard"
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3, delay: 0.31 },
            }}
            exit={{ opacity: 0, y: 20, transition: { duration: 0.3 } }}
          >
            <div className={s.container}>
              <Add />
              <ProductList />
              <ProjectList />
              <GraphicList />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </DCWrapper>
  );
};

export default Dashboard;
